<div class="complete-view">
  <div class="complete-view-test-area"></div>
  <div class="complete-view__body">
    <div *ngIf="continueUrl; else loadingSpinner" class="complete-view__body-content">
      <form method="post" [action]="continueUrl" ngNoForm onsubmit="submitButton.disabled = true; return true;">
        <mat-stepper linear #stepper>

          <!-- profile step -->
          <mat-step
            *ngIf="
              profileFormGroup &&
              (givenNameReq || familyNameReq || countryReq || languageReq)
            "
            [stepControl]="profileFormGroup">
            <ng-template matStepLabel>{{ 'generic.profile' | translate }}</ng-template>

            <div class="complete-view__body-content--header">
              <span class="header-3-bold">{{ 'complete.headline' | translate }}</span>
            </div>

            <div class="complete-view__body-content--message">
              <span>{{ 'complete.sub_headline' | translate }}</span>
            </div>

            <div class="complete-view__body-content--fields">
              <div *ngIf="givenNameReq" class="complete-view__body-content--fields-line">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'overview.given_name' | translate }}</mat-label>
                  <input
                    type="text"
                    name="given_name"
                    matInput
                    required
                    [(ngModel)]="givenNameValue"
                    (input)="updatedGivenName($event)"
                  />
                </mat-form-field>
              </div>

              <div *ngIf="familyNameReq" class="complete-view__body-content--fields-line">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'overview.family_name' | translate }}</mat-label>
                  <input
                    type="text"
                    name="family_name"
                    matInput
                    required
                    [(ngModel)]="familyNameValue"
                    (input)="updatedFamilyName($event)"
                  />
                </mat-form-field>
              </div>

              <div *ngIf="countryReq" class="complete-view__body-content--fields-line">
                <mat-form-field>
                  <mat-label>{{ 'overview.country' | translate }}</mat-label>
                  <mat-select
                    name="country"
                    required
                    [(value)]="selectedCountry"
                    (selectionChange)="updatedCountry($event)"
                  >
                    <mat-option *ngFor="let option of countryOptions" [value]="option.key">
                      {{ option?.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <input type="hidden" name="country" [value]="selectedCountry | lowercase" />
              </div>
              <div *ngIf="languageReq" class="complete-view__body-content--fields-line">
                <mat-form-field>
                  <mat-label>{{ 'account_settings.language.label' | translate }}</mat-label>
                  <mat-select
                    name="language"
                    required
                    [(value)]="selectedLanguage"
                    (selectionChange)="updatedLanguage($event)"
                  >
                    <mat-option *ngFor="let option of languageOptions" [value]="option.key">
                      {{ option?.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <input type="hidden" name="language" [value]="selectedLanguage" />
              </div>
            </div>

            <div class="complete-view__body-content--actions">
                  <button
                    *ngIf="(lastStep$ | async) !== 'profile'; else submitButton"
                    class="btn-right next-btn"
                    type="button"
                    mat-flat-button
                    matStepperNext
                    [disabled]="!!(invalidProfileStepState$ | async)"
                  >
                    {{ 'generic.next' | translate }}
                  </button>

                  <ng-template #submitButton>
                    <button
                      class="btn-right next-btn"
                      name="submitButton"
                      mat-flat-button
                      type="submit"
                      [disabled]="!!(invalidProfileStepState$ | async)"
                    >
                      {{ 'complete.submit_button' | translate }}
                    </button>
                  </ng-template>
                </div>
          </mat-step>

          <!-- Account linking step -->
          <mat-step *ngIf="this.connectionReq">
            <ng-template matStepLabel>{{ 'generic.info' | translate }}</ng-template>
            <div class="complete-view__body-content--header">
              <span class="header-3-bold">{{ 'complete.headline_al' | translate }}</span>
            </div>

            <div class="complete-view__body-content--message">
              <p [innerHTML]="'account_linking.intro' | translate"></p>
            </div>

            <div class="complete-view__body-content--actions">
              <button
                *ngIf="(lastStep$ | async) !== 'linking'; else submitButton"
                class="btn-right next-btn"
                type="button"
                mat-flat-button
                matStepperNext
              >
                {{ 'generic.next' | translate }}
              </button>

              <ng-template #submitButton>
                <button
                  class="btn-right next-btn"
                  mat-flat-button
                  name="submitButton"
                  type="submit"
                >
                  {{ 'complete.submit_button' | translate }}
                </button>
              </ng-template>
            </div>
          </mat-step>

          <!-- Consents step -->
          <mat-step
            *ngIf="consentsFormGroup && clients && clients?.length"
            [stepControl]="consentsFormGroup"
          >
            <ng-template matStepLabel>{{ 'consent.consent' | translate }}</ng-template>

            <div class="complete-view__body-content--header">
              <span class="header-3-bold">{{ 'complete.headline' | translate }}</span>
            </div>
            <!-- Applications Consent -->
            <div
              *ngIf="applicationConsent"
              class="complete-view__body-content--message"
            >
              <p class="application-name">{{ 'applications.' + this.currentClientName + '.name' | translate }}</p>
              <br/>
              <span *ngIf="reconsentReq">{{ 'overview.not_connected_info_label' | translate }}</span>
              <ul *ngIf="reconsentReq" class="consent-info-access-list">
                <li class="consent-info-access-list-item">{{ 'overview.name' | translate }}</li>
                <li class="consent-info-access-list-item">{{ 'overview.email' | translate }}</li>
                <li class="consent-info-access-list-item">{{ 'overview.country' | translate }}</li>
                <li class="consent-info-access-list-item">{{ 'overview.addresses' | translate }}</li>
                <li class="consent-info-access-list-item">{{ 'overview.phones' | translate }}</li>
              </ul>

              <div class="complete-view__body-content--fields">
                <div class="complete-view__body-content--fields-line checkbox-container">
                  <mat-checkbox
                    labelPosition="after"
                    type="checkbox"
                    name="confirm"
                    value="yes"
                    (change)="this.updateTermsState(!($event?.checked))"
                  >
                    <span [innerHTML]="'applications.' + currentClientName + '.accept_terms' | translate"></span>
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <!-- CLAAS ID  Consent-->
            <div
              *ngIf="claasIdConsentIsIncluded"
              class="complete-view__body-content--message"
            >
              <span class="application-name">CLAAS ID</span>
              <div class="complete-view__body-content--fields">
                <div class="complete-view__body-content--fields-line">
                  <span>{{ 'consent.intro_3' | translate }}</span>
                </div>

                <div class="complete-view__body-content--fields-line checkbox-container">
                  <mat-checkbox
                    labelPosition="after"
                    type="checkbox"
                    name="newsletter"
                    value="yes"
                    (change)="this.updateTermsState(!($event?.checked), true)"
                  >
                    <span [innerHTML]="'applications.CLAAS_ID.accept_terms' | translate"></span>
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <div class="complete-view__body-content--actions">
                <button
                  *ngIf="(lastStep$ | async) !== 'consent'; else submitButton"
                  class="btn-right next-btn"
                  type="button"
                  mat-flat-button
                  matStepperNext
                  [disabled]="!!(termsState$ | async)"
                >
                  {{ 'generic.next' | translate }}
                </button>

                <ng-template #submitButton>
                  <button
                    class="btn-right next-btn"
                    mat-button
                    name="submitButton"
                    type="submit"
                    [disabled]="!!(termsState$ | async)"
                  >
                    {{ 'complete.submit_button' | translate }}
                  </button>
                </ng-template>
              </div>

          </mat-step>

          <!-- Newsletter/ Marketing step -->
          <mat-step *ngIf="newsletterReq">
            <ng-template matStepLabel>{{ 'overview.marketing.consent' | translate }}</ng-template>

            <div class="complete-view__body-content--header">
              <span class="header-3-bold">{{ 'overview.marketing.intro_headline' | translate }}</span>
            </div>

            <div class="complete-view__body-content--message">
              <div class="complete-view__body-content--fields">
                <input name="invitationUuid" type="hidden" [value]="invitationUuid">
                <input name="marketingFeedback" type="hidden" [value]="true" />

                <div class="complete-view__body-content--fields-line">
                  <span>{{ 'overview.marketing.headline' | translate }}</span>
                </div>

                <div class="complete-view__body-content--fields-line checkbox-container">
                  <mat-checkbox
                    labelPosition="after"
                    type="checkbox"
                    name="mpGeneralMail"
                    [checked]="!!(marketingPermissionGranted$ | async)"
                    (change)="updateMarketingPermission($event.checked)"
                  >
                    <span>{{ 'overview.central_marketing_permission.consent.acceptance.claas_only' | translate }}</span>
                  </mat-checkbox>
                </div>

                <div
                  *ngIf="marketingPermissionGranted$ | async"
                  class="complete-view__body-content--fields-line"
                >
                  <ul class="marketing-permissions-list">
                    <li>
                      <mat-checkbox
                        name="mpPost"
                        labelPosition="after"
                        [checked]="!!(marketingSpecificPermissionGranted$ | async).post"
                        (change)="updateMarketingPermission($event.checked, 'post')"
                      >
                        <span>{{ 'overview.central_marketing_permission.channels.post' | translate }}</span>
                      </mat-checkbox>
                    </li>

                    <li>
                      <mat-checkbox
                        name="mpPhone"
                        labelPosition="after"
                        [checked]="!!(marketingSpecificPermissionGranted$ | async).phone"
                        (change)="updateMarketingPermission($event.checked, 'phone')"
                      >
                        <span>{{ 'overview.central_marketing_permission.channels.phone' | translate }}</span>
                      </mat-checkbox>
                    </li>

                    <li>
                      <mat-checkbox
                        name="mpEmail"
                        labelPosition="after"
                        [checked]="!!(marketingSpecificPermissionGranted$ | async).email"
                        (change)="updateMarketingPermission($event.checked, 'email')"
                      >
                        <span>{{ 'overview.central_marketing_permission.channels.email' | translate }}</span>
                      </mat-checkbox>
                    </li>

                    <li>
                      <mat-checkbox
                        name="mpPush"
                        labelPosition="after"
                        [checked]="!!(marketingSpecificPermissionGranted$ | async).push"
                        (change)="updateMarketingPermission($event.checked, 'push')"
                      >
                        <span>{{ 'overview.central_marketing_permission.channels.push' | translate }}</span>
                      </mat-checkbox>
                    </li>
                  </ul>
                </div>

                <div
                  *ngIf="marketingPermissionGranted$ | async"
                  class="complete-view__body-content--fields-line"
                >
                  <span>{{ 'overview.central_marketing_permission.consent.subtext.claas_only' | translate }}</span>
                </div>

                <div
                  *ngIf="marketingPermissionGranted$ | async"
                  class="complete-view__body-content--fields-line"
                >
                  <span [innerHtml]="'overview.central_marketing_permission.consent.subtext_2' | translate: {country: this.userCountry}"></span>
                </div>

                <div
                  *ngIf="marketingFootnotes && marketingFootnotes !== ''"
                  class="complete-view__body-content--fields-line"
                >
                  <span>{{ marketingFootnotes }}</span>
                </div>
              </div>
            </div>

            <div class="complete-view__body-content--actions">
              <button
                class="btn-right next-btn"
                mat-button
                name="submitButton"
                type="submit"
              >
                {{ 'complete.submit_button' | translate }}
              </button>
            </div>
          </mat-step>
        </mat-stepper>

      </form>
    </div>

    <ng-template #loadingSpinner>
      <span class="complete-view__body-content-loader">
        <claas-circular-progress
          [color]="secondaryColor"
          [diameter]="largeSize"></claas-circular-progress>
      </span>
    </ng-template>
  </div>
</div>
