import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, forkJoin, tap } from 'rxjs';
import { Icon, IconFontSize, IconSize } from '@claas/claas-form-components';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../shared/services/api.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MarketingPermission } from '../shared/models/marketing-permission.model';
import { filter, map } from 'rxjs/operators';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { IUser } from '../shared/models/auth.model';
import { selectUserDetails } from '../shared/state/user';
import { selectMarketingPermissions } from '../shared/state/user-coordinates/user-coordinates.selectors';
import { Router } from '@angular/router';
import { loadMarketingPermissions } from '../shared/state/user-coordinates/user-coordinates.actions';
import { OptionObs } from '../shared/components/address-view/address-view.component';
export interface DealerResponse {
  dealerName: string;
}

@Component({
  selector: 'app-preference-center',
  templateUrl: './preference-center.component.html',
  styleUrls: ['./preference-center.component.scss']
})
export class PreferenceCenterComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  marketingPermissions$: BehaviorSubject<any> = new BehaviorSubject([]);
  isLoadingMarketingPermissions$: BehaviorSubject<any> = new BehaviorSubject(
    true
  );
  permissionsList: MarketingPermission[] = [];
  claasCount: number = 0;
  dealerCount: number = 0;

  editIcon: Icon = {
    iconId: '001326',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  largeSize = CircularProgressDiameter.LARGE;
  secondaryColor = ProgressColor.SECONDARY;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  isHandset = false;

  private user: IUser | undefined;
  private userCountry: string | undefined;

  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private store: Store,
    private router: Router
  ) {
    this.subscriptions.add(this.isHandset$().subscribe());
  }

  ngOnInit() {
    this.getMarketingPermissions();
    this.getUserCountry();
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  getMarketingPermissions(afterUpdate?: boolean): void {
    this.subscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(
          filter((user: any) => !!user),
          tap((user: IUser) => {
            this.user = user;
            // in case the token is required please use "user.sub" for it

            this.subscriptions.add(
              this.store.select(selectMarketingPermissions).subscribe({
                next: permissions => {
                  this.permissionsList = permissions ? permissions : [];
                  this.permissionsList = this.setPermissionStatus(
                    this.permissionsList
                  );

                  const totalCount = this.getPermissionCount();

                  if (afterUpdate && totalCount < 1) {
                    this.router.navigate(['/']);
                  }

                  const observables = this.fetchDealersNames$();

                  this.subscriptions.add(
                    forkJoin(observables).subscribe({
                      next: dealerPermissionNames => {
                        for (const key in dealerPermissionNames) {
                          this.permissionsList = this.permissionsList.map(
                            target => {
                              if (target.sap_reference_id === key) {
                                return {
                                  ...target,
                                  dealer_name: dealerPermissionNames[key] || key
                                };
                              }
                              return target;
                            }
                          );
                        }
                      },
                      complete: () => {
                        this.isLoadingMarketingPermissions$.next(false);
                        this.marketingPermissions$.next(this.permissionsList);
                      }
                    })
                  );
                }
              })
            );
          })
        )
        .subscribe()
    );
  }

  fetchDealersNames$() {
    const obsArray: OptionObs = {};
    const dealerPermissions = this.permissionsList.filter(
      permission => permission.type === 'dealer' && permission.sap_reference_id
    );

    dealerPermissions.forEach(
      (permission: MarketingPermission) =>
        (obsArray[permission.sap_reference_id] = this.getDealerName(
          permission.sap_reference_id
        ))
    );

    return obsArray;
  }

  getUserCountry() {
    this.store
      .select(selectUserDetails)
      .pipe(filter(user => !!user))
      .subscribe(res => {
        this.userCountry = res?.user_metadata?.country;
      });
  }

  openDialog(context: string, marketingPermission: any): void {
    const data: passedDataToDialog = {
      context,
      userId: this.user?.uuid,
      buttonCancel: 'Cancel',
      buttonSave: 'Apply settings',
      userCountry: this.userCountry
    };

    if (marketingPermission.length) {
      data.marketingPermissions = marketingPermission;
    } else {
      data.marketingPermission = marketingPermission;
    }

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          map((answer: ResponseDataFromDialog) => {
            if (answer) {
              this.store.dispatch(
                loadMarketingPermissions({
                  claasId: this.user?.uuid ? this.user?.uuid : ''
                })
              );

              if (answer.action === 'save') {
                this.marketingPermissions$.next([]);
                this.isLoadingMarketingPermissions$.next(true);
                this.getMarketingPermissions(true);
              }
              if (answer.action === 'delete') {
                this.router.navigate(['/']);
              }
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private setPermissionStatus(input: MarketingPermission[]) {
    const output: MarketingPermission[] = [];

    input.forEach((permission: MarketingPermission) => {
      output.push({
        ...permission,
        subscribed:
          permission.channels.email ||
          permission.channels.phone ||
          permission.channels.push ||
          permission.channels.post
      });
    });

    return output;
  }

  private getPermissionCount() {
    this.dealerCount = this.permissionsList.filter(
      permission => permission.type === 'dealer'
    ).length;

    this.claasCount = this.permissionsList.filter(
      permission => permission.type === 'claas'
    ).length;

    return this.dealerCount + this.claasCount;
  }

  private getDealerName(dealerId: string): Observable<string> {
    return this.apiService
      .getPreferredDealer(dealerId)
      .pipe(map((res: DealerResponse) => res?.dealerName || ''));
  }
}
