<div
  mat-dialog-title
  class="!flex !justify-between !items-center w-full mt-4 mb-0 pb-0"
  #title>
  <h4 class="no-margin">
    {{ 'organization.invitation_request.title' | translate }}
  </h4>

  <button mat-icon-button mat-dialog-close>
    <lib-icon
      class="close-icon"
      iconId="648548"
      namespace="claas-id"
      [size]="24">
    </lib-icon>
  </button>
</div>

<div
  mat-dialog-content
  class="flex flex-col items-start justify-start max-w-full">
  <div class="mx-auto">
    <!-- Content -->
    <div class="leading-5">
      {{
        'organization.invitation_request.description'
          | translate
            : {
                firstName: dialogInput.firstName,
                lastName: dialogInput.lastName
      } }}
    </div>
  </div>
</div>

<mat-dialog-actions class="!flex !flex-row !justify-end !gap-4 mt-6 w-full">
  <claas-button
    [text]="'organization.invitation_request.accept' | translate"
    [buttonClass]="ButtonClass.PRIMARY"
    [size]="ButtonSize.DEFAULT_MEDIUM"
    (onClick)="accept()">
  </claas-button>

  <claas-button
    [text]="'organization.invitation_request.deny' | translate"
    [buttonClass]="ButtonClass.SECONDARY_OUTLINE"
    [size]="ButtonSize.DEFAULT_MEDIUM"
    (onClick)="deny()">
  </claas-button>
</mat-dialog-actions>
