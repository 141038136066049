<div class="user-invitation__wrapper">
  <h1 class="user-invitation__header subtitle-2">
    {{ 'org_access.request.header' | translate }}
  </h1>
  <p *ngIf="accessToken" class="user-invitation__text">
    {{ 'org_access.request.text' | translate: { orgName: orgName } }}
  </p>

  <div *ngIf="!accessToken" class="user-invitation__loader">
    <claas-circular-progress
      [color]="secondaryColor"
      [diameter]="largeSize"></claas-circular-progress>
  </div>
  <div class="user-invitation__dialog-actions">
    <div class="left-side">
      <div class="btn-left">
        <div *ngIf="showSpinner$ | async" class="flex flex-end w-full">
          <span class="flex">
            <claas-circular-progress
              [color]="secondaryColor"
              [diameter]="largeSize">
            </claas-circular-progress>
          </span>
        </div>
      </div>
    </div>
    <div class="right-side">
      <claas-button
        (onClick)="onClose(false)"
        *ngIf="data.buttonCancel"
        [buttonClass]="secondaryTextButtonClass"
        [text]="'generic.deny' | translate"
        class="btn-left">
      </claas-button>

      <claas-button
        (onClick)="onClose(true)"
        *ngIf="data?.buttonSave"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.confirm' | translate"
        class="btn-right">
      </claas-button>
    </div>
  </div>
</div>
