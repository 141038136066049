import {
  IAddressAutocompleteResponse,
  IRegistrationDealerDataResponse,
  IRegistrationPersonalData,
  IRegistrationShopData
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { StateError } from '../index';

export enum RegistrationDialogError {
  NONE,
  STEP_1,
  STEP_2,
  STEP_3,
  STEP_4
}

export interface StatesEntity {
  [key: string]: States[];
}

export interface States {
  key: string;
  value: string;
}

export interface GlobalState {
  activeNotifierId: string | null;
  indicatorVisible: boolean;
  registrationDataPending: boolean;
  registrationPersonalData: IRegistrationPersonalData | null;
  registrationPersonalDataPending: boolean;
  registrationDealerData: IRegistrationDealerDataResponse | null;
  addressAutocompleteSuggestions: IAddressAutocompleteResponse[] | null;
  registrationShopData: IRegistrationShopData | null;
  shopDataLoadError: StateError;
  registrationError: RegistrationDialogError;
  statesDictionary: StatesEntity | null;
}

export const initialState: GlobalState = {
  activeNotifierId: null,
  indicatorVisible: false,
  registrationDataPending: false,
  registrationPersonalData: null,
  registrationPersonalDataPending: false,
  registrationDealerData: null,
  addressAutocompleteSuggestions: null,
  registrationShopData: null,
  shopDataLoadError: null,
  registrationError: RegistrationDialogError.NONE,
  statesDictionary: null
};
