import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InvitationRequestDTO } from '../models/invitation-request.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvitationRequestService {
  constructor(private http: HttpClient) {}

  getOrganisationInvitationRequests(orgId: string, token: string) {
    orgId = encodeURIComponent(orgId as string);
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    };

    return this.http.get<InvitationRequestDTO[]>(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/invitation-request',
      httpHeaders
    );
  }

  denyOrganisationInvitationRequest(
    invitationRequestId: string,
    token: string
  ) {
    invitationRequestId = encodeURIComponent(invitationRequestId);
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    };

    return this.http.delete(
      environment.base_urls.api_base_url +
        '/v3/organizations/invitation-request/' +
        invitationRequestId,
      httpHeaders
    );
  }
}
