export type OrgaAddressTypes = 'INVOICE' | 'DELIVERY';

export class UserOrgaAddress {
  id: string;
  type: OrgaAddressTypes;
  street: string;
  houseNumber?: string;
  house_number?: string;
  addressAddition?: string;
  postbox?: string;
  postalCode?: string;
  postal_code?: string;
  city: string;
  suburb?: string;
  state?: string;
  territory?: string;
  country: string;
  legalName?: string;
  longitude?: number;
  latitude?: number;
  updatedAt?: string;
  updatedby?: string;
  createdAt?: string;
  createdby?: string;

  constructor(value?: any) {
    this.id = value && value.id ? value.id : '';
    this.street = value && value.street ? value.street : '';
    this.houseNumber = value && value.houseNumber ? value.houseNumber : '';
    this.house_number = value && value.house_number ? value.house_number : '';
    this.city = value && value.city ? value.city : '';
    this.postalCode = value && value.postalCode ? value.postalCode : '';
    this.postal_code = value && value.postal_code ? value.postal_code : '';
    this.country = value && value.country ? value.country : 'de';
    this.suburb = value && value.suburb ? value.suburb : '';
    this.addressAddition =
      value && value.addressAddition ? value.addressAddition : '';
    this.state = value && value.state ? value.state : '';
    this.territory = value && value.territory ? value.territory : '';
    this.type = value && value.type ? value.type : 'INVOICE';
  }
}
