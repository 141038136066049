<section
  [ngClass]="data.largeDisplay ? 'dialog-window large' : 'dialog-window'">
  <section class="container">
    <div [ngSwitch]="data.context">
      <ng-container *ngSwitchCase="'profile-avatar'">
        <app-avatar-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [avatarUrl]="data.avatarUrl"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [secondaryTextButtonClass]="
            secondaryTextButtonClass
          "></app-avatar-view>
      </ng-container>

      <ng-container
        *ngSwitchCase="'profile-name'"
        class="dialog-window__name-view">
        <app-name-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-name-view
      ></ng-container>

      <ng-container *ngSwitchCase="'email'" class="dialog-window__email-view">
        <p>Edit Email window</p>
      </ng-container>

      <ng-container
        *ngSwitchCase="'profile-address'"
        class="dialog-window__address-view">
        <app-address-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [primaryTextButtonClass]="primaryTextButtonClass"
          [secondaryTextButtonClass]="
            secondaryTextButtonClass
          "></app-address-view>
      </ng-container>

      <ng-container
        *ngSwitchCase="'profile-phonenumber'"
        class="dialog-window__phonenumber-view">
        <app-phonenumber-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [secondaryTextButtonClass]="
            secondaryTextButtonClass
          "></app-phonenumber-view>
      </ng-container>

      <ng-container *ngSwitchCase="'profile-password'">
        <app-password-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass" />
      </ng-container>

      <ng-container *ngSwitchCase="'profile-language'">
        <app-language-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass" />
      </ng-container>

      <ng-container *ngSwitchCase="'profile-two-factor'">
        <app-two-factor-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data" />
      </ng-container>

      <ng-container *ngSwitchCase="'profile-deletion'">
        <app-delete-account-view
          (closeDialogWindow)="closeDialogWindow($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass" />
      </ng-container>

      <ng-container *ngSwitchCase="'organisation-deletion'">
        <app-confirmation-dialog
          (closeDialogWindow)="closeDialogWindow($event)"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-confirmation-dialog>
      </ng-container>

      <ng-container *ngSwitchCase="'organisation-name'">
        <app-organisation-name-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-organisation-name-view>
      </ng-container>

      <ng-container *ngSwitchCase="'organisation-type'">
        <app-organisation-type-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-organisation-type-view>
      </ng-container>

      <ng-container *ngSwitchCase="'organisation-timezone'">
        <app-organisation-timezone-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-organisation-timezone-view>
      </ng-container>

      <ng-container *ngSwitchCase="'organisation-unitsystem'">
        <app-organisation-unitsystem-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-organisation-unitsystem-view>
      </ng-container>

      <ng-container *ngSwitchCase="'organisation-phonenumber'">
        <app-organisation-phonenumber-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-organisation-phonenumber-view>
      </ng-container>

      <ng-container *ngSwitchCase="'organisation-address'">
        <app-address-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [primaryTextButtonClass]="primaryTextButtonClass"
          [secondaryTextButtonClass]="
            secondaryTextButtonClass
          "></app-address-view>
      </ng-container>

      <ng-container *ngSwitchCase="'organisation-users'">
        <app-organisation-users-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [primaryTextButtonClass]="primaryTextButtonClass"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-organisation-users-view>
      </ng-container>

      <ng-container *ngSwitchCase="'connected-dealers'">
        <app-organisation-connected-dealers-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [primaryTextButtonClass]="primaryTextButtonClass"
          [secondaryTextButtonClass]="
            secondaryTextButtonClass
          "></app-organisation-connected-dealers-view>
      </ng-container>

      <ng-container *ngSwitchCase="'application-dialog'">
        <app-application-dialog
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [primaryTextButtonClass]="primaryTextButtonClass"
          [secondaryTextButtonClass]="secondaryTextButtonClass"
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
        >
        </app-application-dialog>
      </ng-container>

      <ng-container *ngSwitchCase="'admin-invitation'">
        <app-admin-invitation-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass" />
      </ng-container>

      <ng-container *ngSwitchCase="'user-invitation'">
        <app-user-invitation-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass" />
      </ng-container>

      <ng-container *ngSwitchCase="'marketing-permission'">
        <app-marketing-permission
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [primaryTextButtonClass]="primaryTextButtonClass"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-marketing-permission>
      </ng-container>

      <ng-container *ngSwitchCase="'marketing-permission-delete'">
        <app-marketing-permission-delete
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [errorButtonClass]="errorButtonClass"
          [primaryTextButtonClass]="primaryTextButtonClass"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-marketing-permission-delete>
      </ng-container>

      <ng-container *ngSwitchCase="'user-update'">
        <app-user-update-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-user-update-view>
      </ng-container>

      <ng-container *ngSwitchCase="'org-update-request'">
        <app-organisation-update-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-organisation-update-view>
      </ng-container>

      <ng-container *ngSwitchCase="'address-validation'">
        <app-address-validation-view
          (closeDialogWindow)="closeDialogWindow($event)"
          [data]="data">
        </app-address-validation-view>
      </ng-container>
      <ng-container *ngSwitchCase="'marketing-permission-update'">
        <app-marketing-permission-update-view
          (closeDialogWindow)="closeDialogWindow($event)"
          (displayPopup)="displayPopUp($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-marketing-permission-update-view>
      </ng-container>
      <ng-container *ngSwitchCase="'org-deletion-request'">
        <app-organisation-deletion-request-view
          (closeDialogWindow)="closeDialogWindow($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-organisation-deletion-request-view>
      </ng-container>
      <ng-container *ngSwitchCase="'user-deletion'">
        <app-user-deletion-request-view
          (closeDialogWindow)="closeDialogWindow($event)"
          [data]="data"
          [secondaryTextButtonClass]="secondaryTextButtonClass">
        </app-user-deletion-request-view>
      </ng-container>
    </div>
  </section>
</section>
