import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { BehaviorSubject, Subscription, finalize, switchMap } from 'rxjs';
import { errorStatusObject } from '../address-view/address-view.component';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumber } from '../../models/phone-number.model';
import { OrgPhoneNumber } from '../../models/org-phone-number.model';
import { countriesArr } from '../language-view/countries-source';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-organisation-phonenumber-view',
  templateUrl: './organisation-phonenumber-view.component.html',
  styleUrls: ['./organisation-phonenumber-view.component.scss']
})
export class OrganisationPhonenumberViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  tempPhonenumber = '';
  currentPhoneIndex = 0;
  countriesIds = countriesArr.map(el => el.id);
  phoneNumbers: PhoneNumber[] = [];
  subscriptions = new Subscription();
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};
  preferredCountries: string[] | undefined = [];

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.setCurrentPhonenumber();
    this.preferredCountries = this.data.countriesForPhone;
  }

  setCurrentPhonenumber(): void {
    this.tempPhonenumber = this.data.organisationData?.phoneNumber?.phoneNumber
      ? this.data.organisationData?.phoneNumber?.phoneNumber
      : '';
    const currentPhonenumber = new PhoneNumber();

    currentPhonenumber.phone_number_value = this.tempPhonenumber;
    this.phoneNumbers.push(currentPhonenumber);
  }

  updateNumberInvalidState(isInvalid: boolean) {
    this.invalidSaveButtonState$.next(isInvalid);
  }

  updatePhonenumber(currentValue: any) {
    this.tempPhonenumber = currentValue.phone_number_value;
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked(): any {
    const passedObject: ResponseDataFromDialog = {
      context: this.data.context,
      action: 'save'
    };

    const phonenumberUpdate: OrgPhoneNumber = this.preparePhonenumber();

    if (this.data.subContext === 'edit') {
      this.updatePhonenumberDetails(passedObject, phonenumberUpdate);
    } else {
      this.addPhonenumber(passedObject, phonenumberUpdate);
    }

    this.invalidSaveButtonState$.next(true);
  }

  updatePhonenumberDetails(
    passedObject: ResponseDataFromDialog,
    phonenumberUpdate: OrgPhoneNumber
  ): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: this.data.organisationData.auth0Id })
        .pipe(
          switchMap(token =>
            this.apiService.updateOrganisationPhoneNumber(
              this.data.organisationData.auth0Id,
              phonenumberUpdate,
              token
            )
          ),
          finalize(() => this.closeDialogWindow.emit(passedObject))
        )
        .subscribe({
          next: value => {
            // Update the organisation phonenumber
            this.translateService
              .get('personal_data.phonenumber.update.success')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('personal_data.phonenumber.update.fail')
              .subscribe(text => {
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  addPhonenumber(
    passedObject: ResponseDataFromDialog,
    phonenumberUpdate: OrgPhoneNumber
  ): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: this.data.organisationData.auth0Id })
        .pipe(
          switchMap(token =>
            this.apiService.createOrganisationPhoneNumber(
              this.data.organisationData.auth0Id,
              phonenumberUpdate,
              token
            )
          ),
          finalize(() => this.closeDialogWindow.emit(passedObject))
        )
        .subscribe({
          next: value => {
            // add the organisation phonenumber
            this.translateService
              .get('personal_data.phonenumber.add.success')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('personal_data.phonenumber.add.fail')
              .subscribe(text => {
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  preparePhonenumber(): OrgPhoneNumber {
    const phonenumberUpdate = new OrgPhoneNumber();

    phonenumberUpdate.phoneNumber = this.tempPhonenumber;

    return phonenumberUpdate;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
