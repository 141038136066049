import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ButtonClass } from '@claas/claas-form-components';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { passedDataToDialog } from '../../dialog-window/dialog-window.component';
import { BehaviorSubject, Subscription, switchMap } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { OrganizationInvitation } from '../../../models/organisation-invitation.model';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-user-invitation-view',
  templateUrl: './user-invitation-view.component.html',
  styleUrl: './user-invitation-view.component.scss'
})
export class UserInvitationViewComponent implements OnDestroy, OnInit {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;
  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  invitation: OrganizationInvitation | undefined;

  orgName: string = '';
  accessToken: string = '';

  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    if (this.data.organisationInvitation) {
      this.invitation = this.data.organisationInvitation;
      if (this.invitation && this.invitation.auth0Id) {
        const orgId = this.invitation.auth0Id;

        this.subscriptions.add(
          this.authService
            .getTokenSilently$({ organization: orgId, newToken: true })
            .pipe(
              switchMap((token: string) => {
                this.accessToken = token;

                return this.apiService.getOrganisation(orgId, this.accessToken);
              })
            )
            .subscribe({
              next: (result: any) => {
                this.orgName = result.name;
              },
              error: (error: any) => {
                console.log(error.message);
              }
            })
        );
      }
    }
  }

  onClose(accept: boolean) {
    if (this.invitation) {
      this.invitation.status = accept ? 'accepted' : 'denied';
      const invitation = this.invitation;
      const organization = invitation.userId;

      this.subscriptions.add(
        this.apiService
          .setOrganisationInvitationStatus(invitation, organization)
          .subscribe()
      );
    }

    this.closeDialogWindow.emit();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
