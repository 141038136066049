import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, finalize, switchMap } from 'rxjs';
import { errorStatusObject } from '../address-view/address-view.component';
import { Organization } from '../../models/organization.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-organisation-name-view',
  templateUrl: './organisation-name-view.component.html',
  styleUrls: ['./organisation-name-view.component.scss']
})
export class OrganisationNameViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  maxLengthName = 80;
  tempName = '';
  myForm: FormGroup | undefined;
  subscriptions = new Subscription();
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.tempName = this.data.organisationData?.name
      ? this.data.organisationData?.name
      : '';

    this.myForm = this.setForm();
    this.trackForm(this.myForm);
  }

  setForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(this.tempName, [Validators.required])
    });
  }

  trackForm(myForm: FormGroup): void {
    // @ts-ignore
    this.errorsStatusObject$['name'] = new BehaviorSubject<undefined | string>(
      undefined
    );
  }

  updateValidity(input: any) {
    const errorMessage =
      !input.target.value || input.target.value === ''
        ? 'registration.validationMsgs.required'
        : undefined;

    input.target.value = input.target.value.slice(0, this.maxLengthName);
    this.errorsStatusObject$['name']?.next(errorMessage);

    // update the save button status
    this.invalidSaveButtonState$.next(!!errorMessage);
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked(): any {
    const passedObject: ResponseDataFromDialog = {
      context: this.data.context,
      action: 'save'
    };

    const organisationUpdate = this.preUpdateOrganisation();

    this.saveNewNameDetails(passedObject, organisationUpdate);
    this.invalidSaveButtonState$.next(true);
  }

  saveNewNameDetails(
    passedObject: ResponseDataFromDialog,
    organisationUpdate: Organization
  ): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: this.data.organisationData.auth0Id })
        .pipe(
          switchMap(token =>
            this.apiService.updateOrganisation(
              this.data.organisationData.auth0Id,
              organisationUpdate,
              token
            )
          ),
          finalize(() => this.closeDialogWindow.emit(passedObject))
        )
        .subscribe({
          next: value => {
            // Update the organisation data
            this.translateService
              .get('organization.edit_name.success')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('organization.edit_name.fail')
              .subscribe(text => {
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  preUpdateOrganisation(): Organization {
    const organisationUpdate = new Organization();

    switch (this.data.context) {
      case 'organisation-name':
        organisationUpdate.copyOrganisation(this.data.organisationData);
        organisationUpdate.name = this.myForm?.get('name')?.value
          ? this.myForm?.get('name')?.value
          : '';
        break;
    }

    return organisationUpdate;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
