import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ButtonClass,
  ClaasFormComponentsModule
} from '@claas/claas-form-components';
import {
  passedDataToDialog,
  ResponseDataFromDialog
} from '../dialog-window/dialog-window.component';
import { OrganisationUpdateRequest } from '../../models/organisation-update-request.model';
import { BehaviorSubject, Subscription, switchMap, take } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  CircularProgressDiameter,
  ClaasLayoutComponentsModule,
  ProgressColor
} from '@claas/claas-layout-components';
import { OrgAddress } from '../../models/org-address.model';
import { countriesArr } from '../language-view/countries-source';
import { OrgPhoneNumber } from '../../models/org-phone-number.model';
import { Organization } from '../../models/organization.model';
import { combineLatestWith, filter } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-organisation-update-view',
  standalone: true,
  imports: [
    AsyncPipe,
    TranslateModule,
    ClaasLayoutComponentsModule,
    NgIf,
    ClaasFormComponentsModule,
    NgForOf
  ],
  templateUrl: './organisation-update-view.component.html',
  styleUrl: './organisation-update-view.component.scss'
})
export class OrganisationUpdateViewComponent implements OnInit, OnDestroy {
  @Input() secondaryTextButtonClass?: ButtonClass;
  @Input() data: passedDataToDialog = {};
  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();

  primaryButtonClass: ButtonClass = ButtonClass.PRIMARY;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;
  phoneType: 'NEW' | 'OLD' | 'NONE' = 'NONE';
  updatedPhoneNumber: OrgPhoneNumber = new OrgPhoneNumber();

  orgUpdateRequest: OrganisationUpdateRequest | undefined;
  countriesArr = countriesArr;
  orgData: {
    key: string;
    existingValue: any;
    updatedValue: any;
    label: string;
  }[] = [];
  organisation: any | undefined;
  public orgDeliveryAddress: OrgAddress | undefined;
  public orgInvoiceAddress: OrgAddress | undefined;
  subscriptions = new Subscription();

  addressUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  phoneUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  orgUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    if (this.data?.orgUpdateRequest) {
      this.orgUpdateRequest = this.data.orgUpdateRequest;

      if (this.orgUpdateRequest.auth0Id) {
        this.getOrganisation(this.orgUpdateRequest.auth0Id);
      }
    }
  }

  getOrganisation(orgId: string): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: orgId, newToken: true })
        .pipe(
          switchMap((token: string) =>
            this.apiService.getOrganisation(orgId, token)
          )
        )
        .subscribe((res: any) => {
          if (res && res?.type) {
            this.organisation = res;
            this.organisation.type = res.type.toUpperCase();
          }

          if (
            this.organisation &&
            this.organisation.addresses &&
            this.orgUpdateRequest
          ) {
            this.orgDeliveryAddress = this.organisation.addresses.find(
              (address: { type: string }) => address.type === 'DELIVERY'
            );
            this.orgInvoiceAddress = this.organisation.addresses.find(
              (address: { type: string }) => address.type === 'INVOICE'
            );

            this.checkUpdatedOrgFields(this.orgUpdateRequest);
          } else {
            console.log('NEEDS TO BE CLOSED');
          }
        })
    );
  }

  checkUpdatedOrgFields(
    request: OrganisationUpdateRequest,
    parentKey?: string
  ) {
    // check in request which fields were updated
    for (const entry in request) {
      if (request.hasOwnProperty(entry)) {
        // @ts-ignore
        const value = request[entry];

        // if its an object (address, phone number, mobile number) call function recursively
        if (typeof value === 'object' && value !== null) {
          this.checkUpdatedOrgFields(value, entry);
          // the entries org_id, auth0Id, id, longitude and latitude are not relevant for displaying data
        } else if (
          value !== null &&
          entry !== 'org_id' &&
          entry !== 'auth0Id' &&
          entry !== 'id' &&
          entry !== 'longitude' &&
          entry !== 'latitude'
        ) {
          // if the field is not null (nothing changed), add it to userDataChanges
          const dataField: {
            key: string;
            existingValue: any;
            updatedValue: any;
            label: string;
          } = { key: entry, existingValue: '', updatedValue: value, label: '' };

          // Get label without parent key for correct translation
          if (parentKey) {
            // @ts-ignore
            dataField.label = entry.split('.').pop();
          } else {
            dataField.label = entry;
          }

          // Add parent key if it was an object (address, phone number, mobile number)
          if (parentKey) {
            dataField.key = parentKey + '.' + dataField.key;
          }

          if (dataField.key === 'name') {
            // set label for correct translation
            dataField.label = 'registration.formfield.name';
            // set updated & existing value
            dataField.updatedValue = value;
            dataField.existingValue = this.organisation.name;
          }
          if (dataField.key === 'company_type') {
            // set label for correct translation
            dataField.label = 'registration.formfield.type';
            // set updated & existing value
            dataField.updatedValue = value.toUpperCase();
            dataField.existingValue = this.organisation.type;
          }

          if (dataField.key === 'phone_number') {
            // change label for correct translation
            dataField.label = 'overview.phone';
            dataField.updatedValue = value;

            if (this.organisation.phoneNumber) {
              dataField.existingValue =
                this.organisation.phoneNumber.phoneNumber;
            }
          }

          if (dataField.key === 'invoice_address.type') {
            // change label for correct translation
            dataField.label = 'overview.org_address_type';
            if (this.orgInvoiceAddress) {
              dataField.existingValue = this.orgInvoiceAddress.type;
              dataField.updatedValue = value.toUpperCase();
            }
          }

          if (dataField.key === 'invoice_address.country') {
            // change label for correct translation
            dataField.label = 'overview.country';
            dataField.updatedValue = value.toLowerCase();
            if (this.orgInvoiceAddress) {
              dataField.existingValue = this.orgInvoiceAddress.country.toLowerCase();
            }
          }

          if (dataField.key === 'invoice_address.street') {
            // change label for correct translation
            dataField.label = 'registration.formfield.street';
            dataField.updatedValue = value;
            if (this.orgInvoiceAddress) {
              dataField.existingValue = this.orgInvoiceAddress.street;
            }
          }

          if (dataField.key === 'invoice_address.houseNumber') {
            // change label for correct translation
            dataField.label = 'overview.house_number';
            dataField.updatedValue = value;
            if (this.orgInvoiceAddress) {
              dataField.existingValue = this.orgInvoiceAddress.houseNumber;
            }
          }

          if (dataField.key === 'invoice_address.postalCode') {
            // change label for correct translation
            dataField.label = 'registration.formfield.postalCode';
            dataField.updatedValue = value;
            if (this.orgInvoiceAddress) {
              dataField.existingValue = this.orgInvoiceAddress.postalCode;
            }
          }

          if (dataField.key === 'invoice_address.city') {
            // change label for correct translation
            dataField.label = 'overview.city';
            dataField.updatedValue = value;
            if (this.orgInvoiceAddress) {
              dataField.existingValue = this.orgInvoiceAddress.city;
            }
          }

          if (dataField.key === 'invoice_address.state') {
            // set label for correct translation
            dataField.label = 'registration.formfield.state';
            // set updated & existing value
            dataField.updatedValue = value.toLowerCase();
            // @ts-ignore
            if (this.orgInvoiceAddress.state) {
              // @ts-ignore
              dataField.existingValue = this.orgInvoiceAddress.state;
            }
          }

          if (dataField.key === 'invoice_address.suburb') {
            // set label for correct translation
            dataField.label = 'registration.formfield.suburb';
            // set updated & existing value
            dataField.updatedValue = value;
            // @ts-ignore
            if (this.orgInvoiceAddress.suburb) {
              // @ts-ignore
              dataField.existingValue = this.orgInvoiceAddress.suburb;
            }
          }

          if (dataField.key === 'invoice_address.territory') {
            // set label for correct translation
            dataField.label = 'overview.territory';
            // set updated & existing value
            dataField.updatedValue = value;
            // @ts-ignore
            if (this.orgInvoiceAddress.territory) {
              // @ts-ignore
              dataField.existingValue = this.orgInvoiceAddress.territory;
            }
          }

          if (dataField.key === 'invoice_address.addressAddition') {
            // set label for correct translation
            dataField.label = 'overview.address_addition';
            // set updated & existing value
            dataField.updatedValue = value;
            // @ts-ignore
            if (this.orgInvoiceAddress.addressAddition) {
              // @ts-ignore
              dataField.existingValue = this.orgInvoiceAddress.addressAddition;
            }
          }

          if (dataField.key === 'delivery_address.type') {
            // change label for correct translation
            dataField.label = 'overview.org_address_type';
            dataField.updatedValue = value;
            if (this.orgDeliveryAddress) {
              dataField.existingValue = this.orgDeliveryAddress.type;
            }
          }

          if (dataField.key === 'delivery_address.country') {
            // change label for correct translation
            dataField.label = 'overview.country';
            dataField.updatedValue = value.toLowerCase();
            if (this.orgDeliveryAddress) {
              dataField.existingValue = this.orgDeliveryAddress.country.toLowerCase();
            }
          }

          if (dataField.key === 'delivery_address.street') {
            // change label for correct translation
            dataField.label = 'registration.formfield.street';
            dataField.updatedValue = value;
            if (this.orgDeliveryAddress) {
              dataField.existingValue = this.orgDeliveryAddress.street;
            }
          }

          if (dataField.key === 'delivery_address.houseNumber') {
            // change label for correct translation
            dataField.label = 'overview.house_number';
            dataField.updatedValue = value;
            if (this.orgDeliveryAddress) {
              dataField.existingValue = this.orgDeliveryAddress.houseNumber;
            }
          }

          if (dataField.key === 'delivery_address.postalCode') {
            // change label for correct translation
            dataField.label = 'registration.formfield.postalCode';
            dataField.updatedValue = value;
            if (this.orgDeliveryAddress) {
              dataField.existingValue = this.orgDeliveryAddress.postalCode;
            }
          }

          if (dataField.key === 'delivery_address.city') {
            // change label for correct translation
            dataField.label = 'overview.city';
            dataField.updatedValue = value;
            if (this.orgDeliveryAddress) {
              dataField.existingValue = this.orgDeliveryAddress.city;
            }
          }

          if (dataField.key === 'delivery_address.state') {
            // set label for correct translation
            dataField.label = 'registration.formfield.state';
            // set updated & existing value
            dataField.updatedValue = value.toLowerCase();
            // @ts-ignore
            if (this.orgDeliveryAddress.state) {
              // @ts-ignore
              dataField.existingValue = this.orgDeliveryAddress.state;
            }
          }

          if (dataField.key === 'delivery_address.suburb') {
            // set label for correct translation
            dataField.label = 'registration.formfield.suburb';
            // set updated & existing value
            dataField.updatedValue = value;
            // @ts-ignore
            if (this.orgDeliveryAddress.suburb) {
              // @ts-ignore
              dataField.existingValue = this.orgDeliveryAddress.suburb;
            }
          }

          if (dataField.key === 'delivery_address.territory') {
            // set label for correct translation
            dataField.label = 'overview.territory';
            // set updated & existing value
            dataField.updatedValue = value;
            // @ts-ignore
            if (this.orgDeliveryAddress.territory) {
              // @ts-ignore
              dataField.existingValue = this.orgDeliveryAddress.territory;
            }
          }

          if (dataField.key === 'invoice_address.addressAddition') {
            // set label for correct translation
            dataField.label = 'overview.address_addition';
            // set updated & existing value
            dataField.updatedValue = value;
            // @ts-ignore
            if (this.orgDeliveryAddress.addressAddition) {
              // @ts-ignore
              dataField.existingValue = this.orgDeliveryAddress.addressAddition;
            }
          }
          if (dataField.updatedValue !== dataField.existingValue) {
            this.orgData.push(dataField);
          }
        }
      }
    }

    return this.orgData;
  }

  setOrgUpdateRequestStatus(orgId: string, payload: OrganisationUpdateRequest) {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$()
        .pipe(
          switchMap((token: string) =>
            this.apiService.setOrganisationUpdateRequestStatus(
              orgId,
              payload,
              token
            )
          )
        )
        .subscribe({
          next: () => {
            const response: ResponseDataFromDialog = {
              action: 'update',
              updateStatus: payload.status,
              refreshIsRequired: payload.status !== 'denied'
            };

            this.showSpinner$.next(false);
            this.closeDialogWindow.emit(response);
          },
          error: (error: Error) => {
            console.log('STATUS ERROR: ', error);
          }
        })
    );
  }

  updateOrgFields() {
    const updatedOrg = this.organisation;
    const orgDeliveryAddress = this.orgDeliveryAddress;
    const orgDeliveryAddressChanged = this.orgData.find(data =>
      data.key.startsWith('delivery_address')
    );
    const orgInvoiceAddress = this.orgInvoiceAddress;
    const orgInvoiceAddressChanged = this.orgData.find(data =>
      data.key.startsWith('invoice_address')
    );

    // Update organization infos fields
    if (this.orgData.find(data => data.key === 'name')?.updatedValue) {
      // @ts-ignore
      updatedOrg.name = this.orgData.find(
        data => data.key === 'name'
      ).updatedValue;
    }

    // Update organization infos fields
    if (this.orgData.find(data => data.key === 'phone_number')?.updatedValue) {
      const number = new OrgPhoneNumber();
      // @ts-ignore
      number.phoneNumber = this.orgData.find(
        data => data.key === 'phone_number'
      ).updatedValue;

      updatedOrg.phoneNumber = number;
    }

    if (this.orgData.find(data => data.key === 'company_type')?.updatedValue) {
      // @ts-ignore
      updatedOrg.type = this.orgData.find(
        data => data.key === 'company_type'
      ).updatedValue;
    }

    // Update delivery address fields
    if (
      this.orgData.find(data => data.key === 'delivery_address.city')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.city = this.orgData.find(
        data => data.key === 'delivery_address.city'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'delivery_address.country')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.country = this.orgData.find(
        data => data.key === 'delivery_address.country'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'delivery_address.street')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.street = this.orgData.find(
        data => data.key === 'delivery_address.street'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'delivery_address.houseNumber')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.houseNumber = this.orgData.find(
        data => data.key === 'delivery_address.houseNumber'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'delivery_address.postalCode')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.postalCode = this.orgData.find(
        data => data.key === 'delivery_address.postalCode'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'delivery_address.suburb')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.suburb = this.orgData.find(
        data => data.key === 'delivery_address.suburb'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'delivery_address.state')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.state = this.orgData.find(
        data => data.key === 'delivery_address.state'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'delivery_address.territory')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.territory = this.orgData.find(
        data => data.key === 'delivery_address.territory'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'delivery_address.addressAddition')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgDeliveryAddress.addressAddition = this.orgData.find(
        data => data.key === 'delivery_address.addressAddition'
      ).updatedValue;
    }

    // Update invoice address fields
    if (
      this.orgData.find(data => data.key === 'invoice_address.city')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.city = this.orgData.find(
        data => data.key === 'invoice_address.city'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'invoice_address.country')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.country = this.orgData.find(
        data => data.key === 'invoice_address.country'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'invoice_address.street')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.street = this.orgData.find(
        data => data.key === 'invoice_address.street'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'invoice_address.houseNumber')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.houseNumber = this.orgData.find(
        data => data.key === 'invoice_address.houseNumber'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'invoice_address.postalCode')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.postalCode = this.orgData.find(
        data => data.key === 'invoice_address.postalCode'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'invoice_address.suburb')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.suburb = this.orgData.find(
        data => data.key === 'invoice_address.suburb'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'invoice_address.state')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.state = this.orgData.find(
        data => data.key === 'invoice_address.state'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'invoice_address.territory')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.territory = this.orgData.find(
        data => data.key === 'invoice_address.territory'
      ).updatedValue;
    }

    if (
      this.orgData.find(data => data.key === 'invoice_address.addressAddition')
        ?.updatedValue
    ) {
      // @ts-ignore
      orgInvoiceAddress.addressAddition = this.orgData.find(
        data => data.key === 'invoice_address.addressAddition'
      ).updatedValue;
    }

    // Update phone number
    // Check if org already has a phone number
    if (this.orgData.find(data => data.key === 'phone_number')?.existingValue) {
      // null assertion
      if (updatedOrg.phoneNumber) {
        this.updatedPhoneNumber.phoneNumber = this.orgData.find(
          data => data.key === 'phone_number'
        )?.updatedValue;
        this.phoneType = 'OLD';
      }
    } else {
      // @ts-ignore
      if (
        this.orgData.find(data => data.key === 'phone_number')?.updatedValue
      ) {
        // @ts-ignore
        this.updatedPhoneNumber.phoneNumber = this.orgData.find(
          data => data.key === 'phone_number'
        ).updatedValue;
        this.phoneType = 'NEW';
      } else {
        this.phoneUpdated$.next(true);
      }
    }

    // UPDATE OR CREATE A NEW ADDRESS IF NEEDED
    if (orgInvoiceAddressChanged && orgInvoiceAddress) {
      this.saveOrgAddress(updatedOrg, orgInvoiceAddress);
    } else if (orgDeliveryAddressChanged && orgDeliveryAddress) {
      this.saveOrgAddress(updatedOrg, orgDeliveryAddress);
    } else {
      this.addressUpdated$.next(true);
    }

    this.addressUpdated$.subscribe(finished => {
      if (finished) {
        if (!(this.phoneType === 'NONE')) {
          this.savePhoneNumber(
            this.phoneType,
            updatedOrg.auth0Id,
            this.updatedPhoneNumber
          );
        } else {
          this.phoneUpdated$.next(true);
        }
      }
    });

    this.addressUpdated$
      .pipe(
        combineLatestWith(this.phoneUpdated$),
        filter(([address, phone]) => address && phone)
      )
      .subscribe(() => {
        this.subscriptions.add(
          this.auth
            .getTokenSilently$()
            .pipe(
              switchMap(token =>
                this.apiService.updateOrganisation(
                  this.organisation.auth0Id,
                  updatedOrg,
                  token
                )
              )
            )
            .subscribe({
              next: () => {
                this.orgUpdated$.next(true);
              },
              error: (error: Error) => {
                console.log('ERROR ORG UPDATE: ', error);
              }
            })
        );
      });
  }

  savePhoneNumber(
    phoneType: string,
    orgId: string,
    phoneNumber: OrgPhoneNumber
  ) {
    this.phoneUpdated$.next(false);
    if (phoneType === 'NEW') {
      this.subscriptions.add(
        this.auth
          .getTokenSilently$()
          .pipe(
            switchMap(token =>
              this.apiService.createOrganisationPhoneNumber(
                orgId,
                phoneNumber,
                token
              )
            )
          )
          .subscribe({
            next: () => {
              this.phoneUpdated$.next(true);
            }
          })
      );
    } else if (phoneType === 'OLD') {
      this.subscriptions.add(
        this.auth
          .getTokenSilently$()
          .pipe(
            switchMap(token =>
              this.apiService.updateOrganisationPhoneNumber(
                orgId,
                phoneNumber,
                token
              )
            )
          )
          .subscribe({
            next: () => {
              this.phoneUpdated$.next(true);
            }
          })
      );
    }
  }

  saveOrgAddress(org: Organization, addr: OrgAddress) {
    this.addressUpdated$.next(false);
    const address = new OrgAddress();
    Object.keys(addr).forEach(key => {
      // @ts-ignore
      address[key] = addr[key];
    });

    if (address.new && org.auth0Id) {
      this.subscriptions.add(
        this.apiService
          .createOrganisationAddress(org.auth0Id, address)
          .subscribe({
            next: () => {
              this.addressUpdated$.next(true);
            },
            error: (error: Error) => {
              console.log('ERROR CREATE ADDRESS: ', error);
            }
          })
      );
    } else {
      this.subscriptions.add(
        this.auth
          .getTokenSilently$()
          .pipe(
            switchMap(token =>
              this.apiService.updateOrganisationAddress(
                org.auth0Id!,
                address.id,
                address.getOrgAddress(),
                token
              )
            )
          )
          .subscribe({
            next: () => {
              this.addressUpdated$.next(true);
            },
            error: (error: Error) => {
              console.log('ERROR ADDRESS UPDATE: ', error);
            }
          })
      );
    }
  }

  getCountry(country: string) {
    const c = this.countriesArr.find(function (element) {
      return element.id === country.toLowerCase();
    });
    return c === undefined ? '' : c.label;
  }

  onClose(accepted: boolean): void {
    this.showSpinner$.next(true);
    if (accepted) {
      this.updateOrgFields();
    } else if (accepted === false && this.orgUpdateRequest) {
      this.orgUpdateRequest.status = 'denied';
      this.setOrgUpdateRequestStatus(
        this.organisation.auth0Id,
        this.orgUpdateRequest
      );
    }

    this.addressUpdated$
      .pipe(
        combineLatestWith(this.phoneUpdated$, this.orgUpdated$),
        filter(([address, phone, org]) => address && phone && org)
      )
      .subscribe(() => {
        if (this.orgUpdateRequest) {
          this.orgUpdateRequest.status = accepted ? 'accepted' : 'denied';
          this.setOrgUpdateRequestStatus(
            this.organisation.auth0Id,
            this.orgUpdateRequest
          );
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
