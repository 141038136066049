import { createAction, props } from '@ngrx/store';
import {
  IAddressAutocompleteRequest,
  IAddressAutocompleteResponse,
  IPersonalDataRegistrationRequest,
  IRegistrationDealerDataResponse,
  IRegistrationPersonalData,
  IRegistrationShopData
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { StateError } from '../index';
import { StatesEntity } from './global.state';

export const allGlobalActions = {
  rehydrateState: createAction('[Global] Rehydrate State')
};

export const registrationDataPendingUpdated = createAction(
  '[Global] Registration Data Pending updated',
  props<{ registrationDataPending: boolean }>()
);

export const loadRegistrationPersonalData = createAction(
  '[Global] Load Registration Personal Data',
  props<{ country: string; language: string }>()
);

export const registrationPersonalDataLoaded = createAction(
  '[Global] Registration Personal Data loaded',
  props<{ registrationPersonalData: IRegistrationPersonalData }>()
);

export const registrationPersonalDataNotLoaded = createAction(
  '[Global] Registration Personal Data Not loaded',
  props<{ organisationError: StateError }>()
);

export const registrationPersonalDataPendingUpdated = createAction(
  '[Global] Registration Personal Data Pending updated',
  props<{ registrationPersonalDataPending: boolean }>()
);

export const loadRegistrationDealerData = createAction(
  '[Global] Load Registration Dealer Data',
  props<{ language: string }>()
);

export const registrationDealerDataLoaded = createAction(
  '[Global] Registration Dealer Data loaded',
  props<{ registrationDealerData: IRegistrationDealerDataResponse }>()
);

export const registrationDealerDataNotLoaded = createAction(
  '[Global] Registration Dealer Data Not loaded',
  props<{
    registrationDealerData: IRegistrationDealerDataResponse;
    organisationError: StateError;
  }>()
);

export const loadAddressAutocompleteSuggestionsData = createAction(
  '[Global] Load AddressAutocompleteSuggestions Data',
  props<{ addressDetailsRequest: IAddressAutocompleteRequest }>()
);

export const addressAutocompleteSuggestionsDataLoaded = createAction(
  '[Global] Address Autocomplete Suggestions Data loaded',
  props<{ addressAutocompleteSuggestions: IAddressAutocompleteResponse[] }>()
);

export const addressAutocompleteSuggestionsDataNotLoaded = createAction(
  '[Global] Address Autocomplete Suggestions Data Not loaded',
  props<{ organisationError: StateError }>()
);

export const loadRegistrationShopData = createAction(
  '[Global] Load Registration Shop Data',
  props<{ country: string }>()
);

export const registrationShopDataLoaded = createAction(
  '[Global] Registration Shop Data loaded',
  props<{ registrationShopData: IRegistrationShopData }>()
);

export const registrationShopDataNotLoaded = createAction(
  '[Global] Registration Shop Data Not loaded',
  props<{ shopDataLoadError: StateError }>()
);

export const updateRegistrationPersonalData = createAction(
  '[Global] Update Registration Personal Data',
  props<{ request: IPersonalDataRegistrationRequest; language: string }>()
);

export const registrationPersonalDataUpdated = createAction(
  '[Global] Registration Personal Data updated'
);

export const registrationPersonalDataNotUpdated = createAction(
  '[Global] Registration Personal Data Not updated',
  props<{ organisationError: StateError }>()
);

export const clearRegistrationData = createAction(
  '[Global] Clear Registration Data'
);

export const loadStatesDictionaryData = createAction(
  '[Global] Load State Dictionary Data',
  props<{ country: string }>()
);

export const statesDictionaryDataLoaded = createAction(
  '[Global] State Dictionary Data loaded',
  props<{ statesDictionary: StatesEntity | null }>()
);
