import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { MarketingPermission } from '../../models/marketing-permission.model';
import { environment } from '../../../../environments/environment';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Observable, of, Subscription, tap } from 'rxjs';
import { OptionObs } from '../address-view/address-view.component';
import { catchError, map } from 'rxjs/operators';
import { DealerResponse } from '../../../preference-center/preference-center.component';
import {
  ButtonClass,
  ButtonSize,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MarketingPermissionPatch } from '../../models/marketing-permission-patch.model';
import { Location } from '@angular/common';

declare const window: any;

@Component({
  selector: 'app-marketing-permission-submit',
  templateUrl: './marketing-permission-submit-view.component.html',
  styleUrl: './marketing-permission-submit-view.component.scss'
})
export class MarketingPermissionSubmitViewComponent
  implements OnInit, OnDestroy
{
  largeSize = CircularProgressDiameter.LARGE;
  secondaryColor = ProgressColor.SECONDARY;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  isHandset = false;
  primaryButtonClass = ButtonClass.PRIMARY;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;

  subscriptions = new Subscription();

  public dealerMarketingPermissions: MarketingPermission[] = [];
  public claasMarketingPermission: MarketingPermission =
    new MarketingPermission();
  public dealerMarketingInvitations: MarketingPermission[] = [];
  public claasMarketingInvitation: MarketingPermission =
    new MarketingPermission();

  public editable: Boolean = true;
  public claasId: string = '';
  public email: string = '';
  public invitationIds: string[] = [];
  public pwChangeTicket: string = '';
  public marketingInvitation: MarketingPermission[] = [];
  public marketingPermissions: MarketingPermission[] = [];
  public update: Boolean = false;
  public consent: Boolean = false;
  public unsubscribe: Boolean = false;
  public doi: Boolean = false;
  public token: string = '';
  public feedback: string = '';
  public profileEmail: string = '';

  size = CircularProgressDiameter.SMALL;
  color = ProgressColor.PRIMARY;
  public showSpinner = true;
  public showRegSpinner = false;
  public showInvCheckbox = false;
  public consentGiven = false;
  public claasPermissionUUID: string = '';
  public submitPageType: string = '';
  public dealerName: string = '';

  public showFeedback = false;
  public userCountry: string = '';
  public footnote: string = '';
  public unsubscribeInvitations = false;
  public disableCompleteReg = false;
  public updateRequired = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private location: Location
  ) {
    this.subscriptions.add(this.isHandset$().subscribe());
  }

  ngOnInit() {
    // remove #-characters from url
    const url = this.router.url;
    if (url.includes('#')) {
      window.location.href = url.replace('#', '');
    }

    // get required complete fields from url
    this.route.queryParams.subscribe(res => {
      this.claasId = res['claas_id'];
      this.pwChangeTicket = res['pw_change_ticket'];

      // check for one or more Invitation Ids
      if (res['invitation_uuids'] && res['invitation_uuids'].indexOf(',') > -1)
        this.invitationIds = res['invitation_uuids'].split(',');
      else if (res['invitation_uuids'] && res['invitation_uuids'].length > 0)
        this.invitationIds.push(res['invitation_uuids']);

      if (res['email']) {
        this.profileEmail = res['email'];
      }

      this.submitPageType = res['type'];
      // New Invitation from Dealer, show invitation
      if (res['type'] == 'new') {
        this.update = false;
        this.consent = false;
      }

      // MP Update from Dealer, show invitations
      if (res['type'] == 'update') {
        this.update = true;
        this.consent = false;
      }

      // MP actived via link, show consent message
      if (res['type'] == 'consent') {
        this.update = true;
        this.consent = true;
      }

      // Unsubscribe page
      if (res['type'] == 'unsubscribe') {
        this.unsubscribe = true;
      }

      // DOI
      if (res['type'] == 'doi') {
        this.doi = true;
      }

      if (res['existingClaasPermission']) {
        this.claasPermissionUUID = res['existingClaasPermission'];
      }

      if (res['token']) {
        this.token = res['token'];
      }

      if (res['userCountry']) {
        this.userCountry = res['userCountry'];
        this.getMarketingFootnote(this.userCountry);
      } else {
        // fallback: englisch
        this.getMarketingFootnote('us');
      }

      if (res['UpdateRequired']) {
        this.updateRequired = res['UpdateRequired'];
      }

      // get Marketing Invitations
      this.getMarketingInfos();

      const observables = this.fetchDealersNames$();
      this.subscriptions.add(
        forkJoin(observables).subscribe({
          next: dealerPermissionNames => {
            for (const key in dealerPermissionNames) {
              this.dealerMarketingPermissions =
                this.dealerMarketingPermissions.map(target => {
                  if (target.sap_reference_id === key) {
                    return {
                      ...target,
                      dealer_name: dealerPermissionNames[key] || key
                    };
                  }
                  return target;
                });
            }
          }
        })
      );

      if (!this.unsubscribe) {
        // Set dealer name if possible
        let dealerInvitations = this.marketingInvitation.filter(
          i => i.type === 'dealer'
        );
        if (dealerInvitations.length == 1)
          this.dealerName = dealerInvitations[0].dealer_name;
      }

      this.editable = true;

      // customer gave his marketing permission on a web form and CLAAS Id is already existing
      if (this.update && this.consent) {
        // Accept all invitations here automatically
        for (let invitation of this.invitationIds) {
          this.acceptMarketingInvitation(this.claasId, invitation, null);
        }
        this.showSpinner = false;
        this.translate
          .get('submit.consent_updated')
          .subscribe((text: string) => (this.feedback = text));
        this.showFeedback = true;
      }
    });
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  getMarketingInfos() {
    // find uuids
    var invitationString = ';';
    if (this.invitationIds.length > 1) {
      invitationString = this.invitationIds.join(',');
    } else {
      invitationString = this.invitationIds[0];
    }
    try {
      if (this.token != null && this.token.length > 0) {
        if (this.doi) {
          // if there are no marketing permissions, get marketing invitations for DOI
          this.getMarketingInvitations(this.claasId, this.token).subscribe(
            res => {
              this.unsubscribeInvitations = true;
              // get dealer invitations
              for (let invitation of this.dealerMarketingInvitations) {
                this.marketingPermissions.push(invitation);
              }

              // get claas invitations
              if (this.claasMarketingInvitation) {
                this.marketingPermissions.push(this.claasMarketingInvitation);
              }

              // Show checkbox for accepting invitation or updating permission if there is one
              if (this.marketingPermissions[0]) {
                this.showInvCheckbox = true;
              }
              this.showSpinner = false;
            }
          );
        }
        if (this.unsubscribe) {
          // get all marketing permissions, so the user can unsubscribe them
          this.getMarketingPermissions(this.claasId, this.token).subscribe(
            res => {
              // type = unsubscribe: get marketing permissions / invitations (fallback). all permissions / invitations (fallback) are displayed
              // get dealer marketing permissions
              for (let permission of this.dealerMarketingPermissions) {
                this.marketingPermissions.push(permission);
              }

              // get claas marketing permissions
              if (this.claasMarketingPermission) {
                this.marketingPermissions.push(this.claasMarketingPermission);
              }

              // if there are no permissions, show existing invitations
              if (!this.marketingPermissions.length) {
                // if there are no marketing permissions, get marketing invitations for DOI
                this.getMarketingInvitations(
                  this.claasId,
                  this.token
                ).subscribe(res => {
                  this.unsubscribeInvitations = true;
                  // get dealer invitations
                  for (let invitation of this.dealerMarketingInvitations) {
                    this.marketingPermissions.push(invitation);
                  }

                  // get claas invitations
                  if (this.claasMarketingInvitation) {
                    this.marketingPermissions.push(
                      this.claasMarketingInvitation
                    );
                  }
                });
              }
              // Show checkbox for accepting invitation or updating permission if there is one
              if (this.marketingInvitation[0] || this.marketingPermissions[0]) {
                this.showInvCheckbox = true;
              }
              this.showSpinner = false;
            }
          );
        }
      } else {
        this.getMarketingInvitationsByInvIds(
          this.claasId,
          invitationString
        ).subscribe(res => {
          // type = new: get marketing invitations. only one invitation is displayed, the other one gets automatically accepted with the same channels
          if (!this.update && !this.doi && !this.updateRequired) {
            if (this.invitationIds.length != 0) {
              if (this.dealerMarketingInvitations.length) {
                // choose which invitation to display
                // dealer permission existing: show dealer invitation
                for (let inv of this.dealerMarketingInvitations) {
                  this.marketingInvitation.push(inv);
                }
              } else {
                // no dealer permission existing: show claas invitation
                this.marketingInvitation.push(this.claasMarketingInvitation);
              }
            }
          }

          // type = update: get marketing invitations. all existing invitations are displayed
          // OR
          // type = new & UpdateRequired: get marketing invitations. display both invitations (Workaround for "Update Required" Cases)
          if (
            (this.update && !this.consent) ||
            (!this.update && !this.doi && this.updateRequired)
          ) {
            if (this.invitationIds.length != 0)
              // find claas invitation or dealer invitation with fitting uuid from URL parameter
              for (let i = 0; i < this.invitationIds.length; i++) {
                // invitation is from dealer
                const foundDealerInvitation =
                  this.dealerMarketingInvitations.find(
                    inv => inv.invitation_uuid === this.invitationIds[i]
                  );
                if (foundDealerInvitation) {
                  this.marketingInvitation.push(foundDealerInvitation);
                }

                // invitation is from claas
                if (
                  this.claasMarketingInvitation.invitation_uuid ==
                  this.invitationIds[i]
                ) {
                  this.marketingInvitation.push(this.claasMarketingInvitation);
                }
              }
          }

          // Show checkbox for accepting invitation or updating permission if there is one
          if (this.marketingInvitation[0] || this.marketingPermissions[0]) {
            this.showInvCheckbox = true;
          }
          this.showSpinner = false;
        });
        // }
      }
    } catch (err) {
      this.showInvCheckbox = false;
    }
  }

  completeRegistration(event: any) {
    event.preventDefault();
    this.showRegSpinner = true;
    // Grant consent for CLAAS ID Portal
    this.apiService.grantClaasIdConsent(this.claasId).subscribe(res => {
      // redirect to application for password change
      window.location.href = this.pwChangeTicket;
    });
  }

  updateMarketingInfos(inv: MarketingPermission[]) {
    this.editable = false;

    for (let invitation of inv) {
      // send HTTPS Request to Backend
      let payload: MarketingPermissionPatch = new MarketingPermissionPatch();
      payload.email = invitation.channels.email;
      payload.email_string = '';
      payload.phone = invitation.channels.phone;
      payload.push = invitation.channels.push;
      payload.post = invitation.channels.post;

      // accept marketing invitation with selected channels for each invitation
      this.acceptMarketingInvitation(
        this.claasId,
        invitation.invitation_uuid,
        payload
      );

      // Only update CLAAS MP when a new dealer initially invites
      if (
        !this.update &&
        !this.consent &&
        this.claasMarketingPermission &&
        this.invitationIds.length == 1 &&
        this.claasPermissionUUID &&
        !this.updateRequired
      ) {
        // Update CLAAS MP with selected channels for dealer permission
        this.updateMarketingPermission(
          this.claasId,
          this.claasPermissionUUID,
          payload
        );
      }
    }

    this.feedback = this.translate.instant(
      'overview.marketing.permission_update'
    );
  }

  denyChanges() {
    for (let inv of this.marketingInvitation) {
      this.acceptMarketingInvitation(this.claasId, inv.invitation_uuid, null);
    }
    this.feedback = this.translate.instant('overview.marketing.feedback_given');
  }

  // Accepts invitations by invitationIds from path
  setMarketingInfos(inv: MarketingPermission) {
    this.editable = false;

    // Send HTTPS Request to Backend if an invitation needs to be accepted
    let payload: MarketingPermissionPatch = new MarketingPermissionPatch();
    payload.email = inv.channels.email;
    payload.email_string = '';
    payload.phone = inv.channels.phone;
    payload.push = inv.channels.push;
    payload.post = inv.channels.post;

    // accept all marketing invitations with selected channels
    for (let invitation of this.invitationIds) {
      this.acceptMarketingInvitation(this.claasId, invitation, payload);
    }

    // Only update CLAAS MP when a new dealer initially invites
    if (
      !this.update &&
      !this.consent &&
      this.claasMarketingPermission &&
      this.invitationIds.length == 1 &&
      this.claasPermissionUUID
    ) {
      // Update CLAAS MP with selected channels for dealer permission
      this.updateMarketingPermission(
        this.claasId,
        this.claasPermissionUUID,
        payload
      );
    }
  }

  next(inv: any, stepper: any) {
    stepper.next();
    // type = new, inv = displayed marketing invitation
    if (
      !this.doi &&
      !this.update &&
      !this.consent &&
      !this.unsubscribe &&
      !this.updateRequired
    ) {
      // accepts marketing invitations by invitation id's from path with given channels
      this.setMarketingInfos(inv);
      stepper.next();
    }

    // type = update, inv = all displayed marketing invitations
    if (
      (!this.doi && !this.unsubscribe) ||
      (this.update && !this.consent && !this.unsubscribe) ||
      (!this.update && !this.consent && this.updateRequired)
    ) {
      // accepts all displayed marketing invitations with given channels
      this.updateMarketingInfos(inv);
      stepper.next();
    }

    // type = unsubscribe, inv = all displayed markting permissions / invitations (fallback)
    if (this.unsubscribe) {
      for (let permission of inv) {
        if (this.unsubscribeInvitations) {
          // No permissions -> Accept or deny invitations
          this.updateMarketingInfos(inv);
        } else {
          // Update permissions
          this.updateMarketingPermissions(permission);
        }
        stepper.next();
      }
    }

    if (this.doi) {
      // accepts all displayed marketing invitations with given channels
      this.updateMarketingInfos(inv);
      stepper.next();
    }
  }

  updateMarketingPermissions(permission: MarketingPermission) {
    // Send HTTPS Request to Backend if an invitation needs to be accepted
    let payload: MarketingPermissionPatch = new MarketingPermissionPatch();
    payload.email = permission.channels.email;
    payload.email_string = '';
    payload.phone = permission.channels.phone;
    payload.push = permission.channels.push;
    payload.post = permission.channels.post;

    // Update marketing permission with selected channels
    this.updateMarketingPermission(
      this.claasId,
      permission.permission_uuid,
      payload
    );
  }

  unsubscribeAll() {
    for (let permission of this.marketingPermissions) {
      permission.channels.email = false;
      permission.channels.phone = false;
      permission.channels.push = false;
      permission.channels.post = false;
    }
  }

  fetchDealersNames$() {
    const obsArray: OptionObs = {};

    this.dealerMarketingInvitations.forEach(
      (inv: MarketingPermission) =>
        (obsArray[inv.sap_reference_id] = this.getDealerName(
          inv.sap_reference_id
        ))
    );

    this.dealerMarketingPermissions.forEach(
      (permission: MarketingPermission) =>
        (obsArray[permission.sap_reference_id] = this.getDealerName(
          permission.sap_reference_id
        ))
    );

    return obsArray;
  }

  private getDealerName(dealerId: string): Observable<string> {
    return this.apiService.getPreferredDealer(dealerId).pipe(
      map((res: DealerResponse) => res?.dealerName || ''),
      catchError(() => of(dealerId))
    );
  }

  getMarketingFootnote(country: string) {
    // @ts-ignore
    this.footnote = environment.marketing_footnotes[country];
  }

  getPasswordResetLink(userId: string, token: string) {
    this.apiService.getPasswordResetLink(userId, token).subscribe(
      (res: Params) => {
        this.pwChangeTicket = res['url'];
      },
      err => {
        this.disableCompleteReg = true;
        const text = this.translate.instant(
          'overview.central_marketing_permission.submit_page.password_reset_error'
        );
        this.snackBar.open(text, '', {
          duration: 5000,
          panelClass: 'error'
        });
      }
    );
  }

  skipMpContinueRegistration(event: any, inv: any) {
    // Accept mp invitation with all channels=false if user skipped directly to claas id registration
    event.preventDefault();
    const payload = {
      email: false,
      email_string: '',
      phone: false,
      push: false,
      post: false
    };
    // Type = New
    if (
      !this.doi &&
      !this.update &&
      !this.consent &&
      !this.unsubscribe &&
      !this.updateRequired &&
      this.invitationIds
    ) {
      // Accept all marketing invitations with channels=false
      for (let invitation of this.invitationIds) {
        this.acceptMarketingInvitation(this.claasId, invitation, payload);
      }
    }

    // redirect to application for password change
    window.location.href = this.pwChangeTicket;
  }

  acceptMarketingInvitation(
    claasId: string,
    invitationId: string,
    payload: any
  ) {
    this.apiService
      .acceptMarketingInvitation(claasId, invitationId, payload)
      .subscribe(res => {});
  }

  updateMarketingPermission(
    claasId: string,
    permissionId: string,
    payload: MarketingPermissionPatch
  ) {
    // Update a permission for a given CLAAS Id and permission Id
    this.apiService
      .updateMarketingPermission(claasId, permissionId, payload)
      .subscribe(
        res => {
          // this.getMarketingPermissions(claasId);
        },
        err => {
          const text = this.translate.instant(
            'overview.marketing.setpermission_error'
          );
          this.snackBar.open(text, '', {
            duration: 5000,
            panelClass: 'error'
          });
        }
      );
  }

  getMarketingInvitationsByInvIds(
    claasId: string,
    invitationId: string
  ): Observable<void> {
    // Get a list of Marketing Invitations for a given Claas Id and UUIDs
    this.dealerMarketingInvitations = [];
    this.claasMarketingInvitation = new MarketingPermission();

    return new Observable<void>(observer => {
      this.apiService
        .getMarketingInvitationsByInvIds(claasId, invitationId)
        .subscribe(
          (res: MarketingPermission[]) => {
            for (let marketingInvitation of res) {
              if (
                marketingInvitation.type === 'claas' &&
                this.claasMarketingInvitation.invitation_uuid !=
                  marketingInvitation.invitation_uuid
              ) {
                // CLAAS Marketing Invitation
                this.claasMarketingInvitation = marketingInvitation;
              } else if (
                marketingInvitation.type === 'dealer' &&
                !this.dealerMarketingInvitations.find(
                  inv =>
                    inv.invitation_uuid === marketingInvitation.invitation_uuid
                )
              ) {
                // Dealer Marketing Invitation
                this.dealerMarketingInvitations.push(marketingInvitation);
              }
            }
            observer.next();
            observer.complete();
          },
          err => {
            const text = this.translate.instant(
              'overview.marketing.getinvitation_error'
            );
            this.snackBar.open(text, '', {
              duration: 5000,
              panelClass: 'error'
            });
            this.showSpinner = false;
            this.showInvCheckbox = false;
            observer.error(err);
          }
        );
    });
  }

  getMarketingPermissions(
    claasId: string,
    token: string
  ): Observable<MarketingPermission[]> {
    this.dealerMarketingPermissions = [];
    this.claasMarketingPermission = new MarketingPermission();

    return this.apiService
      .getMarketingPermissionsUnsubscribe(claasId, this.token)
      .pipe(
        tap(res => {
          for (let marketingPermission of res) {
            if (
              marketingPermission.type === 'claas' &&
              this.claasMarketingPermission.permission_uuid !=
                marketingPermission.permission_uuid
            ) {
              // CLAAS Marketing Permission
              this.claasMarketingPermission = marketingPermission;
            } else if (
              marketingPermission.type === 'dealer' &&
              !this.dealerMarketingPermissions.find(
                permission =>
                  permission.permission_uuid ===
                  marketingPermission.permission_uuid
              )
            ) {
              // Dealer Marketing Permission
              this.dealerMarketingPermissions.push(marketingPermission);
            }
          }
        }),
        catchError(err => {
          const text = this.translate.instant(
            'overview.marketing.getpermission_error'
          );
          this.snackBar.open(text, '', {
            duration: 5000,
            panelClass: 'error'
          });
          return of();
        })
      );
  }

  getMarketingInvitations(
    claasId: string,
    token: string
  ): Observable<MarketingPermission[]> {
    this.dealerMarketingInvitations = [];
    this.claasMarketingInvitation = new MarketingPermission();

    return this.apiService.getMarketingInvitations(claasId, token).pipe(
      tap(res => {
        for (let marketingInvitation of res) {
          if (
            marketingInvitation.type === 'claas' &&
            this.claasMarketingInvitation.invitation_uuid !=
              marketingInvitation.invitation_uuid
          ) {
            // CLAAS Marketing Permission
            this.claasMarketingInvitation = marketingInvitation;
          } else if (
            marketingInvitation.type === 'dealer' &&
            !this.dealerMarketingInvitations.find(
              inv => inv.invitation_uuid === marketingInvitation.invitation_uuid
            )
          ) {
            // Dealer Marketing Permission
            this.dealerMarketingInvitations.push(marketingInvitation);
          }
        }
      }),
      catchError(err => {
        const text = this.translate.instant(
          'overview.marketing.getinvitation_error'
        );
        this.snackBar.open(text, '', {
          duration: 5000,
          panelClass: 'error'
        });
        return of();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
