import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserAddress } from '../models/user-address.model';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FieldDetails } from '../components/address-view/address-view.component';
import { OrgAddressPatch } from '../models/org-address-patch.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(
    private apiService: ApiService,
    private translate: TranslateService
  ) {}

  existingAddressesFormatsObject: any = {};
  currentAddressFormatObject: FieldDetails = {};

  getAddressesFormats(country: string): Observable<any> {
    return this.getSelectedAddressFormat$(country).pipe(
      map(singleFormat => {
        const multipleFormFields: any = {};
        multipleFormFields[country] =
          singleFormat.allOrganizationDataRows.filter(
            (row: any[]) => row.length
          );
        this.existingAddressesFormatsObject = multipleFormFields;
      })
    );
  }

  setCurrentAddressFormatObject(currentCountry: string): void {
    const currentAddressFormatArray = this.existingAddressesFormatsObject[
      currentCountry
    ].flatMap((subArray: any) => [...subArray]);
    this.currentAddressFormatObject = {};
    currentAddressFormatArray.map(
      (details: any) => (this.currentAddressFormatObject[details.id] = details)
    );
  }

  getSelectedAddressFormat$(country: string): Observable<any> {
    return this.apiService.getFormFieldsForCountry(country);
  }

  validateUserAddress(address: UserAddress) {
    return this.getAddressesFormats(address.country).pipe(
      switchMap(() => {
        this.setCurrentAddressFormatObject(address.country);
        let payload = {
          city: address.city,
          country: address.country,
          county: '',
          streetNumber: address.house_number,
          postalCode: address.postal_code,
          state: address.state,
          street: address.street,
          territory: address.territory,
          suburb: address.suburb
        };
        const payloadString: string = JSON.stringify(payload);

        const language = this.translate.currentLang;

        return this.apiService.validateAddress(payloadString, language).pipe(
          // @ts-ignore
          map((res: any[]) => {
            const addresses: UserAddress[] = [];
            for (const proposedAddress of res) {
              const address = new UserAddress();
              address.street = proposedAddress['street'];
              address.house_number = proposedAddress['streetNumber'];
              address.postal_code = proposedAddress['postalCode'];
              address.city = proposedAddress['city'];
              address.country = proposedAddress['country']?.toLowerCase();
              address.suburb = proposedAddress['suburb'];
              address.state = proposedAddress['state']?.toLowerCase();
              address.territory = proposedAddress['territory'];
              address.latitude = proposedAddress['latitude'];
              address.longitude = proposedAddress['longitude'];
              // only use first 3 address proposals
              if (addresses.length < 3) {
                addresses.push(address);
              }
            }

            const equalAddress = this.compareUserAddresses(address, addresses);
            if (equalAddress) {
              this.extendWithCoordinatesFromAddressPoposal(address, addresses);
            }
            return { equalAddress, addresses };
          })
        );
      })
    );
  }

  validateOrgAddress(address: OrgAddressPatch) {
    return this.getAddressesFormats(address.country).pipe(
      switchMap(() => {
        this.setCurrentAddressFormatObject(address.country);
        let payload = {
          city: address.city,
          country: address.country,
          county: '',
          streetNumber: address.houseNumber,
          postalCode: address.postalCode,
          state: address.state,
          street: address.street,
          territory: address.territory,
          suburb: address.suburb
        };
        const payloadString: string = JSON.stringify(payload);

        const language = this.translate.currentLang;

        return this.apiService.validateAddress(payloadString, language).pipe(
          // @ts-ignore
          map((res: any[]) => {
            let addresses: OrgAddressPatch[] = [];

            for (let proposedAddress of res) {
              let address = new OrgAddressPatch();
              address.street = proposedAddress['street'];
              address.houseNumber = proposedAddress['streetNumber'];
              address.postalCode = proposedAddress['postalCode'];
              address.city = proposedAddress['city'];
              address.country = proposedAddress['country']?.toLowerCase();
              address.suburb = proposedAddress['suburb'];
              address.state = proposedAddress['state']?.toLowerCase();
              address.territory = proposedAddress['territory'];
              address.latitude = proposedAddress['latitude'];
              address.longitude = proposedAddress['longitude'];

              // only use first 3 address proposals
              if (addresses.length < 3) {
                addresses.push(address);
              }
            }

            const equalAddress = this.compareOrgAddresses(address, addresses);
            if (equalAddress) {
              this.extendWithCoordinatesFromAddressPoposal(address, addresses);
            }
            return { equalAddress, addresses };
          })
        );
      })
    );
  }

  compareUserAddresses(
    usersAddress: UserAddress,
    proposedAddresses: UserAddress[]
  ) {
    for (const proposedAddress of proposedAddresses) {
      let same = true;
      if (
        this.currentAddressFormatObject['city'] &&
        usersAddress.city?.toString().toLowerCase() !==
          proposedAddress.city?.toString().toLowerCase()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['street'] &&
        usersAddress.street?.toString().toLowerCase() !==
          proposedAddress.street?.toString().toLowerCase()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['houseNumber'] &&
        usersAddress.house_number != null &&
        usersAddress.house_number?.toString() !==
          proposedAddress.house_number?.toString()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['postalCode'] &&
        usersAddress.postal_code?.toString() !==
          proposedAddress.postal_code?.toString()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['state'] &&
        usersAddress.state?.toString().toLowerCase() !==
          proposedAddress.state?.toString().toLowerCase()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['suburb'] &&
        ((usersAddress.suburb && !proposedAddress.suburb) ||
          (!usersAddress.suburb && proposedAddress.suburb) ||
          usersAddress.suburb?.toString().toLowerCase() !==
            proposedAddress.suburb?.toString().toLowerCase())
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['territory'] &&
        usersAddress.territory?.toString() !==
          proposedAddress.territory?.toString()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['territory'] &&
        usersAddress.territory?.toString() !==
          proposedAddress.territory?.toString()
      ) {
        same = false;
      }

      // found same address
      if (same) {
        return proposedAddress;
      }

      break;
    }
    // sometimes the address validation service is returning the same address
    return false;
  }

  compareOrgAddresses(
    orgAddress: OrgAddressPatch,
    proposedAddresses: OrgAddressPatch[]
  ) {
    for (const proposedAddress of proposedAddresses) {
      let same = true;
      if (
        this.currentAddressFormatObject['city'] &&
        orgAddress.city?.toString().toLowerCase() !==
          proposedAddress.city?.toString().toLowerCase()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['street'] &&
        orgAddress.street?.toString().toLowerCase() !==
          proposedAddress.street?.toString().toLowerCase()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['houseNumber'] &&
        orgAddress.houseNumber != null &&
        orgAddress.houseNumber?.toString() !==
          proposedAddress.houseNumber?.toString()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['postalCode'] &&
        orgAddress.postalCode?.toString() !==
          proposedAddress.postalCode?.toString()
      ) {
        same = false;
      }

      if (
        this.currentAddressFormatObject['state'] &&
        orgAddress.state?.toString().toLowerCase() !==
          proposedAddress.state?.toString().toLowerCase()
      ) {
        same = false;
      }

      if (this.currentAddressFormatObject['suburb']) {
        if (
          orgAddress.suburb?.toString().toLowerCase() !==
          proposedAddress.suburb?.toString().toLowerCase()
        ) {
          same = false;
        }
      }

      if (
        this.currentAddressFormatObject['territory'] &&
        orgAddress.territory?.toString() !==
          proposedAddress.territory?.toString()
      ) {
        same = false;
      }

      // found same address
      if (same) {
        return proposedAddress;
      }
    }
    // sometimes the address validation service is returning the same address
    return false;
  }

  private extendWithCoordinatesFromAddressPoposal(
    usersAddress: UserAddress | OrgAddressPatch,
    proposedAddresses: (UserAddress | OrgAddressPatch)[]
  ) {
    const equalAddress = proposedAddresses.find(address => {
      if (
        this.currentAddressFormatObject['city'] &&
        usersAddress.city?.toString().toLowerCase() !==
          address.city?.toString().toLowerCase()
      ) {
        return false;
      }

      if (
        this.currentAddressFormatObject['street'] &&
        usersAddress.street?.toString().toLowerCase() !==
          address.street?.toString().toLowerCase()
      ) {
        return false;
      }

      if (usersAddress instanceof UserAddress) {
        if (
          this.currentAddressFormatObject['houseNumber'] &&
          usersAddress.house_number?.toString() !==
            (address as UserAddress).house_number?.toString()
        ) {
          return false;
        }

        if (
          this.currentAddressFormatObject['postalCode'] &&
          usersAddress.postal_code?.toString() !==
            (address as UserAddress).postal_code?.toString()
        ) {
          return false;
        }
      } else {
        if (
          this.currentAddressFormatObject['houseNumber'] &&
          usersAddress.houseNumber?.toString() !==
            (address as OrgAddressPatch).houseNumber?.toString()
        ) {
          return false;
        }

        if (
          this.currentAddressFormatObject['postalCode'] &&
          usersAddress.postalCode?.toString() !==
            (address as OrgAddressPatch).postalCode?.toString()
        ) {
          return false;
        }
      }

      if (
        this.currentAddressFormatObject['state'] &&
        usersAddress.state?.toString().toLowerCase() !==
          address.state?.toString().toLowerCase()
      ) {
        return false;
      }

      if (this.currentAddressFormatObject['suburb']) {
        if (
          usersAddress.suburb?.toString().toLowerCase() !==
          address.suburb?.toString().toLowerCase()
        ) {
          return false;
        }
      }

      return true;
    });

    if (
      equalAddress == null ||
      (!equalAddress.latitude && !equalAddress.longitude)
    ) {
      return;
    }

    usersAddress.latitude = equalAddress.latitude;
    usersAddress.longitude = equalAddress.longitude;
  }
}
