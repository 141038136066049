import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
// import { TranslateModule } from '@ngx-translate/core';

// import { ClaasIconElementsComponentModule } from '@claas/claas-icon-elements-component';
import {
  MAT_DIALOG_DATA,
  // MatDialogActions,
  // MatDialogClose,
  // MatDialogContent,
  MatDialogRef,
  // MatDialogTitle
} from '@angular/material/dialog';
import {
  ButtonClass,
  ButtonSize
  // ClaasFormComponentsModule
} from '@claas/claas-form-components';

export interface InvitationRequestDialogInput {
  firstName: string;
  lastName: string;
}

export interface InvitationRequestDialogOutput {
  accept: boolean;
}

@Component({
  selector: 'app-invitation-request-dialog',
  // TODO: cannot use standalone component because BrowserModule is imported in
  // ClaasFormComponentsModule which leads to NG5100 runtime error

  // standalone: true,
  // imports: [
  //   ClaasFormComponentsModule,
  //   ClaasIconElementsComponentModule,
  //   TranslateModule,
  //   MatDialogActions,
  //   MatDialogClose,
  //   MatDialogContent,
  //   MatDialogTitle
  // ],
  templateUrl: './invitation-request-dialog.component.html',
  styleUrl: './invitation-request-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitationRequestDialogComponent {
  public ButtonClass = ButtonClass;
  public ButtonSize = ButtonSize;

  public dialogRef = inject<MatDialogRef<InvitationRequestDialogComponent>>(
    MatDialogRef<InvitationRequestDialogComponent>
  );
  public dialogInput = inject<InvitationRequestDialogInput>(MAT_DIALOG_DATA);

  public accept() {
    this.dialogRef.close({
      accept: true
    } as InvitationRequestDialogOutput);
  }

  public deny() {
    this.dialogRef.close({
      accept: false
    } as InvitationRequestDialogOutput);
  }
}
