<div class="user-deletion-request-view">
  <div class="user-deletion-request-view__header">
    <p class="user-deletion-request-view__header-text" *ngIf="this.dealerName">{{ 'deletion.user_deletion' | translate : { dealerName: this.dealerName} }}</p>
    <p class="user-deletion-request-view__header-text" *ngIf="!this.dealerName">{{ 'deletion.user_deletion_claas' | translate }}</p>

  </div>
  <div class="user-deletion-request-view-buttons">
    <claas-button
      *ngIf="data?.buttonCancel"
      [buttonClass]="secondaryTextButtonClass"
      [disabled]="(showSpinner$ | async) === true"
      [text]="'generic.deny' | translate"
      (onClick)="onClose(false)"
      class="btn-left">
    </claas-button>

    <claas-button
      *ngIf="data?.buttonSave"
      [buttonClass]="errorButtonClass"
      [disabled]="(showSpinner$ | async) === true"
      [text]="'generic.confirm' | translate"
      (onClick)="onClose(true)"
      class="btn-right" />
  </div>
</div>
