<div class="marketing-permission-submit-view">
  <mat-horizontal-stepper class="marketing-permission-submit-view-stepper" [linear]="true" #stepper>
    <!-- New Marketing Invitation: Marketing Invitation gets accepted -->
    <mat-step *ngIf="!this.update && !this.consent && !this.unsubscribe && !this.doi"
              label="{{ 'submit.step1' |  translate }}" [editable]="this.editable">
      <div class="marketing-permission-submit-view__header">
        <h1 class='marketing-permission-submit-view__header-title'>{{ 'submit.header_mp' |  translate }}</h1>
        <claas-circular-progress
          *ngIf="this.showSpinner"
          class="spinner"
          [color]="color"
          [diameter]="size"
        ></claas-circular-progress>
        <div *ngIf="this.showInvCheckbox">
          <div *ngIf="!this.updateRequired">{{ 'submit.text_mp' |  translate }} </div>
        </div>
        <ul class="marketing-permission-submit-view__body-list">
          <li *ngFor="let inv of this.marketingInvitation" class="marketing-permission-submit-view__body-list--item">
            <!-- Workaround for update required cases -->
            <div class="marketing-permission-submit-view__body-subtitle" *ngIf="this.updateRequired">{{ 'submit.text_mp' |  translate }}</div>
            <div class="marketing-permission-submit-view__body-text">
              <span *ngIf="(inv.type | lowercase) == 'claas'">{{ 'submit.consent.acceptance.claas_only' | translate }}</span>
              <span *ngIf="(inv.type | lowercase) == 'dealer' && !this.updateRequired">{{ 'submit.consent.acceptance.claas_and_dealer' | translate }}</span>
              <!-- Workaround for update required cases -->
              <span *ngIf="(inv.type | lowercase) == 'dealer' && this.updateRequired">{{ 'submit.consent.acceptance.dealer_only' | translate }}</span>
            </div>
            <div class="marketing-permission-submit-view__body-checkbox">
              <li>
                <claas-checkbox
                  [label]="'submit.channels.email' | translate"
                  [(ngModel)]="inv.channels.email"
                ></claas-checkbox>
                <div *ngIf="inv.channels.email">
                    <span *ngIf="this.profileEmail" class="marketing-permission-submit-view__body-sub">{{ 'overview.marketing.used_email' | translate }};
                      {{ this.profileEmail }}
                    </span>
                </div>
              </li>
              <li>
                <claas-checkbox
                  [label]="'submit.channels.phone' | translate"
                  [(ngModel)]="inv.channels.phone"
                ></claas-checkbox>
              </li>
              <li>
                <claas-checkbox
                  [label]="'submit.channels.push' | translate"
                  [(ngModel)]="inv.channels.push"
                ></claas-checkbox>
              </li>
              <li>
                <claas-checkbox
                  [label]="'submit.channels.post' | translate"
                  [(ngModel)]="inv.channels.post"
                ></claas-checkbox>
              </li>
            </div>

            <!-- Suffix Text Dealer & CLAAS if type is "new" -->
            <div class="marketing-permission-submit-view__footer-suffix-text" *ngIf="this.submitPageType == 'new' && !this.updateRequired">
              {{ 'submit.consent.subtext.claas_and_dealer' | translate}}
              <br>
              <span [innerHTML]="'submit.consent.subtext_2' | translate: {country: this.userCountry}"></span>
              <div class="marketing-permission-submit-view__footer-marketing-footnote">
                <span> <sup>1</sup> {{ this.footnote }}</span>
              </div>
              <div class="marketing-permission-submit-view__footer-marketing-footnote">
                <span *ngIf="(inv.type | lowercase) == 'dealer'"><sup>2</sup> <app-dealer-address-footnote [dealerId]="inv.sap_reference_id"></app-dealer-address-footnote>
                  </span>
              </div>
            </div>
            <!-- If type is new & update Required, show text based on invitation type-->
            <div *ngIf="this.submitPageType == 'new' && this.updateRequired" class="marketing-permission-submit-view__footer-suffix-text">
              <span
                *ngIf="(inv.type | lowercase) == 'claas'">{{ 'submit.consent.subtext.claas_only' | translate}}</span>
              <span
                *ngIf="(inv.type | lowercase) == 'dealer'">{{ 'submit.consent.subtext.claas_and_dealer' | translate}}</span>
              <br>
              <span [innerHTML]="'submit.consent.subtext_2' | translate: {country: this.userCountry}"></span>
              <br>
              <div class="marketing-permission-submit-view__footer-marketing-footnote">
                <!-- Differentiate between CLAAS & Dealer-->
                <span *ngIf="(inv.type | lowercase) == 'claas'"><sup>1</sup> {{ this.footnote }}</span>
                <br>
                <span *ngIf="(inv.type | lowercase) == 'dealer'"><sup>1</sup>
                  <app-dealer-address-footnote [dealerId]="inv.sap_reference_id"></app-dealer-address-footnote></span>
              </div>
            </div>
            <div *ngIf="!this.updateRequired" class="marketing-permission-submit-view__actions">
                <claas-button
                  [text]="'generic.save' | translate"
                  [buttonClass]="primaryButtonClass"
                  [size]="mediumButtonSize"
                  (click)="next(inv,stepper)"></claas-button>
              <div style="text-align: center;">
                <a href="#" (click)="skipMpContinueRegistration($event, inv)" type="register">{{ 'overview.marketing.registration' |  translate }}</a>
              </div>
            </div>
          </li>
          <div *ngIf="this.updateRequired" class="marketing-permission-submit-view__actions">
              <claas-button
                [text]="'generic.save' | translate"
                [buttonClass]="primaryButtonClass"
                [size]="mediumButtonSize"
                (click)="next(this.marketingInvitation,stepper)"></claas-button>
            <div style="text-align: center;">
              <a href="#" (click)="skipMpContinueRegistration($event, this.marketingInvitation)" type="register">{{ 'overview.marketing.registration' |  translate }}</a>
            </div>
          </div>
        </ul>
      </div>
      <div *ngIf="!this.showInvCheckbox && !this.showSpinner">
        {{ 'overview.marketing.no_invitations' |  translate }}
      </div>
    </mat-step>
    <mat-step label="{{ 'submit.step2' |  translate }}"
              *ngIf="!this.update && !this.consent && !this.unsubscribe && !this.doi">
      <h1 class='marketing-permission-submit-view__header-title'>{{ 'submit.header_consent' |  translate }}</h1>
      <div class='marketing-permission-submit-view__body-text'>{{ 'submit.text_consent1' |  translate }}</div>
      <div class='marketing-permission-submit-view__body-text'>{{ 'submit.text_consent2' |  translate }}</div>
      <div class="marketing-permission-submit-view__body-choices">
        <claas-checkbox
          name="consent"
          [(ngModel)]="this.consentGiven"
        ></claas-checkbox>
        <div [innerHTML]="'submit.accept_terms' | translate"></div>
      </div>
      <div class="marketing-permission-submit-view__actions" *ngIf="this.showRegSpinner">
        <claas-circular-progress
          class="spinner"
          [color]="color"
          [diameter]="size"
        ></claas-circular-progress>
      </div>
      <div class="marketing-permission-submit-view__actions" *ngIf="!this.showRegSpinner">
        <claas-button
          [text]="'submit.step2' | translate"
          [buttonClass]="primaryButtonClass"
          [size]="mediumButtonSize"
          [disabled]="!this.consentGiven"
          (click)="completeRegistration($event);"></claas-button>
      </div>
    </mat-step>


    <!-- Existing Marketing Permission: New Marketing Invitation gets accepted -->
    <mat-step label="{{ 'overview.marketing.confirm_update2' |  translate }}"
              [editable]="this.editable" *ngIf="this.update && !this.consent">
      <div class="marketing-permission-submit-view__header">
        <h1 class='marketing-permission-submit-view__header-title'>{{ 'submit.header_mp' |  translate }}</h1>
        <div *ngIf="this.showSpinner">
          <claas-circular-progress
            class="spinner"
            [color]="color"
            [diameter]="size"
          ></claas-circular-progress>
        </div>
        <div *ngIf="this.showInvCheckbox">
          <h3 class="marketing-permission-submit-view__header-subtitle">{{ 'overview.marketing.confirm_info' |  translate }}</h3>
          <div class ="marketing-permission-submit-view__body">
            <ul class="marketing-permission-submit-view__body-list">
              <li *ngFor="let inv of this.marketingInvitation" class="marketing-permission-submit-view__body-list--item">
                <div class="marketing-permission-submit-view__body-checkbox">
                  <div *ngIf="inv.type === 'dealer'">
                    <h3 class="marketing-permission-submit-view__body-subtitle">{{ 'overview.marketing.dealers' |  translate }} {{ inv.dealer_name }}</h3>
                    <br>
                    <span [innerHTML]="'submit.consent.acceptance.dealer_only' | translate"></span>
                  </div>
                  <div *ngIf="inv.type === 'claas'">
                    <h3 class="marketing-permission-submit-view__body-subtitle">{{ 'overview.marketing.claas_group_claas' |  translate }}</h3>
                    <br>
                    <span [innerHTML]="'submit.consent.acceptance.claas_only' | translate"></span>
                  </div>

                  <li>
                    <claas-checkbox
                      [label]="'submit.channels.email' | translate"
                      [(ngModel)]="inv.channels.email"
                    ></claas-checkbox>
                    <div *ngIf="inv.channels.email">
                    <span *ngIf="this.profileEmail" class="marketing-permission-submit-view__body-sub">{{ 'overview.marketing.used_email' | translate }}:
                      {{ this.profileEmail }}
                    </span>
                    </div>
                  </li>
                  <li>
                    <claas-checkbox
                      [label]="'submit.channels.phone' | translate"
                      [(ngModel)]="inv.channels.phone"
                    ></claas-checkbox>
                  </li>
                  <li>
                    <claas-checkbox
                      [label]="'submit.channels.push' | translate"
                      [(ngModel)]="inv.channels.push"
                    ></claas-checkbox>
                  </li>
                  <li>
                    <claas-checkbox
                      [label]="'submit.channels.post' | translate"
                      [(ngModel)]="inv.channels.post"
                    ></claas-checkbox>
                  </li>
                </div>
                <div class ="marketing-permission-submit-view__footer">
                  <div class ="marketing-permission-submit-view__footer-suffix-text">
                    <!-- Subtext depending on type-->
                    <span *ngIf="inv.type === 'dealer'" [innerHTML]="'submit.consent.subtext.claas_and_dealer' | translate"></span>
                    <span *ngIf="inv.type === 'claas'" [innerHTML]="'submit.consent.subtext.claas_only' | translate"></span> <br>
                    <span [innerHTML]="'submit.consent.subtext_2' | translate: {country: this.userCountry}"></span>
                    <!-- Footnote -->
                    <div class="marketing-permission-submit-view__footer-marketing-footnote">
                      <!-- CLAAS only-->
                      <div class="marketing-permission-submit-view__footer-marketing-footnote">
                        <span *ngIf="inv.type === 'claas'"><sup>1</sup> {{ this.footnote }}</span>
                        <span *ngIf="inv.type === 'dealer'"><sup>1</sup> <app-dealer-address-footnote [dealerId]="inv.sap_reference_id"></app-dealer-address-footnote></span>
                      </div>
                    </div>
                    <br><br>
                  </div>
                </div>
              </li>
              <h3 class="marketing-permission-submit-view__body-subtitle">{{ 'overview.marketing.confirm_agree' |  translate }}</h3>
              <div class="marketing-permission-submit-view__actions">
                <claas-button
                  [text]="'overview.marketing.confirm_accept' | translate"
                  [buttonClass]="primaryButtonClass"
                  [size]="mediumButtonSize"
                  (click)="next(this.marketingInvitation,stepper)"></claas-button>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="!this.showInvCheckbox && !this.showSpinner">
        {{ 'overview.marketing.no_invitations' |  translate }}
      </div>
    </mat-step>
    <mat-step *ngIf="this.update && !this.consent && !this.doi" label="Feedback">
      <div class="submit-text">{{ this.feedback }}</div>
    </mat-step>


    <!-- Web Form Registration: Accept Marketing Invitation and show Feedback -->
    <mat-step *ngIf="this.update && this.consent" label="{{ 'overview.marketing.consent' |  translate }}">
      <div *ngIf="this.showSpinner">
        <claas-circular-progress
          class="spinner"
          [color]="color"
          [diameter]="size"
        ></claas-circular-progress>
      </div>
      <div class="marketing-permission-submit-view__body-text" style="margin-bottom: 50px;" *ngIf="this.showFeedback">{{ this.feedback }}</div>
      <div class="marketing-permission-submit-view__actions" *ngIf="this.update && this.consent && this.pwChangeTicket">
<!--         Todo: check button-->
        <button mat-button matStepperNext type="button">{{ 'generic.next' |  translate }}</button>
      </div>
    </mat-step>
    <mat-step *ngIf="this.update && this.consent && this.pwChangeTicket && !this.unsubscribe" label="{{ 'submit.complete_registration.button' |  translate }}">
      <div class="marketing-permission-submit-view__body-text" [innerHtml]="'submit.complete_registration.text' | translate"></div>
        <div class="marketing-permission-submit-view__body-choices">
          <claas-checkbox
            name="consent"
            [(ngModel)]="this.consentGiven"
          ></claas-checkbox>
          <div [innerHTML]="'submit.complete_registration.consent_text' | translate"></div>
        </div>
      <div *ngIf="this.showRegSpinner" class="marketing-permission-submit-view__actions" >
        <claas-circular-progress
          class="spinner"
          [color]="color"
          [diameter]="size"
        ></claas-circular-progress>
      </div>
      <div *ngIf="!this.showRegSpinner" class="marketing-permission-submit-view__actions">
        <claas-button
          [text]="'submit.complete_registration.button' | translate"
          [buttonClass]="primaryButtonClass"
          [size]="mediumButtonSize"
          [disabled]="!this.consentGiven"
          (click)="completeRegistration($event);"></claas-button>
      </div>
    </mat-step>

    <!-- Unsubscribe: Show all marketing permissions -->
    <mat-step *ngIf="this.unsubscribe" label="{{ 'overview.marketing.unsubscribe' |  translate }}">
      <div class="marketing-permission-submit-view__header">
        <h1 class='marketing-permission-submit-view__header-title'>{{ 'overview.marketing.unsubscribe' |  translate }}</h1>
        <div *ngIf="this.showSpinner" class="marketing-permission-submit-view__actions" >
          <claas-circular-progress
            class="spinner"
            [color]="color"
            [diameter]="size"
          ></claas-circular-progress>
        </div>
      </div>
      <div class="marketing-permission-submit-view__body">
        <div *ngIf="this.showInvCheckbox">
          <div class="marketing-permission-submit-view__body-button">
            <claas-button
              [text]="'overview.marketing.unsubscribe_all' | translate"
              [buttonClass]="primaryButtonClass"
              [size]="mediumButtonSize"
              (click)="unsubscribeAll();"></claas-button>
          </div>
          <ul class="marketing-permission-submit-view__body-list">
            <li *ngFor="let permission of this.marketingPermissions" class="marketing-permission-submit-view__body-list--item">
              <div class="marketing-permission-submit-view__body-checkbox">


                <div *ngIf="permission.type === 'dealer'">
                  <h3 class="marketing-permission-submit-view__body-subtitle">{{ 'overview.marketing.dealers' |  translate }} {{ permission.dealer_name }}</h3>
                  <br>
                  <span [innerHTML]="'submit.consent.acceptance.dealer_only' | translate"></span>
                </div>
                <div *ngIf="permission.type === 'claas'">
                  <h3 class="marketing-permission-submit-view__body-subtitle">{{ 'overview.marketing.claas_group_claas' |  translate }}</h3>
                  <br>
                  <span [innerHTML]="'submit.consent.acceptance.claas_only' | translate"></span>
                </div>
                <li>
                  <claas-checkbox
                    [label]="'submit.channels.email' | translate"
                    [(ngModel)]="permission.channels.email"
                  ></claas-checkbox>
                  <div *ngIf="permission.channels.email">
                    <span *ngIf="this.profileEmail" class="marketing-permission-submit-view__body-sub">{{ 'overview.marketing.used_email' | translate }}:
                      {{ this.profileEmail }}
                    </span>
                  </div>
                </li>
                <li>
                  <claas-checkbox
                    [label]="'submit.channels.phone' | translate"
                    [(ngModel)]="permission.channels.phone"
                  ></claas-checkbox>
                </li>
                <li>
                  <claas-checkbox
                    [label]="'submit.channels.push' | translate"
                    [(ngModel)]="permission.channels.push"
                  ></claas-checkbox>
                </li>
                <li>
                  <claas-checkbox
                    [label]="'submit.channels.post' | translate"
                    [(ngModel)]="permission.channels.post"
                  ></claas-checkbox>
                </li>
                <div class="marketing-permission-submit-view__footer-marketing-footnote">
                  <!-- Differentiate between CLAAS & Dealer-->
                  <span *ngIf="(permission.type | lowercase) == 'claas'"><sup>1</sup> {{ this.footnote }}</span>
                  <br>
                  <span *ngIf="(permission.type | lowercase) == 'dealer'"><sup>1</sup>
                  <app-dealer-address-footnote [dealerId]="permission.sap_reference_id"></app-dealer-address-footnote></span>
                </div>
              </div>
            </li>
            <div class="marketing-permission-submit-view__actions">
              <claas-button
                [text]="'generic.save' | translate"
                [buttonClass]="primaryButtonClass"
                [size]="mediumButtonSize"
                (click)="next(this.marketingPermissions, stepper)"></claas-button>
            </div>
          </ul>
        </div>
      </div>
    </mat-step>
    <mat-step *ngIf="this.unsubscribe" label="{{ 'overview.marketing.feedback' |  translate }}">
      <div class="marketing-permission-submit-view__body-text" style="margin-bottom: 50px;">{{ 'submit.consent_updated' | translate }}
      </div>
    </mat-step>

    <!-- Get valid marketing permission from migrated invitation: Marketing Permission DOI Service -->
    <mat-step *ngIf="this.doi" label="{{ 'overview.marketing.confirm_label' |  translate }}"
              [editable]="this.editable">
      <div class="marketing-permission-submit-view__header">
        <h1 class='marketing-permission-submit-view__header-title'>{{ 'overview.marketing.confirm' |  translate }}</h1>
        <div *ngIf="this.showSpinner" class="marketing-permission-submit-view__actions" >
          <claas-circular-progress
            class="spinner"
            [color]="color"
            [diameter]="size"
          ></claas-circular-progress>
        </div>
        <div *ngIf="this.showInvCheckbox">
          <!-- Append dealer Name if one invitation is dealer-->
          <ul class="marketing-permission-submit-view__body-list">
            <li class="marketing-permission-submit-view__body-list--item" *ngFor="let inv of this.marketingPermissions">
              <div class="marketing-permission-submit-view__body-subtitle" *ngIf="this.updateRequired">{{ 'submit.text_mp' |  translate }}</div>
              <div class="marketing-permission-submit-view__body-text">
                <span *ngIf="(inv.type | lowercase) == 'claas'">{{ 'submit.consent.acceptance.claas_only' | translate }}</span>
                <span *ngIf="(inv.type | lowercase) == 'dealer' && !this.updateRequired">{{ 'submit.consent.acceptance.claas_and_dealer' | translate }}</span>
                <!-- Workaround for update required cases -->
                <span *ngIf="(inv.type | lowercase) == 'dealer' && this.updateRequired">{{ 'submit.consent.acceptance.dealer_only' | translate }}</span>
              <div class="marketing-permission-submit-view__body-text">
                <div *ngIf="inv.type === 'dealer'">
                  <h3 class="marketing-permission-submit-view__body-title">{{ 'overview.marketing.dealers' |  translate }} {{ inv.dealer_name }}</h3>
                  <br>
                  <span [innerHTML]="'submit.consent.acceptance.dealer_only' | translate"></span>
                </div>
                <div *ngIf="inv.type === 'claas'">
                  <h3 class="marketing-permission-submit-view__body-title">{{ 'overview.marketing.claas_group_claas' |  translate }}</h3>
                  <br>
                  <span [innerHTML]="'submit.consent.acceptance.claas_only' | translate"></span>
                </div>
              </div>
              <div class="marketing-permission-submit-view__body-checkbox">
                <li>
                  <claas-checkbox
                    [label]="'submit.channels.email' | translate"
                    [(ngModel)]="inv.channels.email"
                  ></claas-checkbox>

                  <div *ngIf="inv.channels.email" >
                    <span *ngIf="this.profileEmail" class="marketing-permission-submit-view__body-sub">{{ 'overview.marketing.used_email' | translate }}:
                      {{ this.profileEmail }}
                    </span>
                  </div>
                </li>
                <li>
                  <claas-checkbox
                    [label]="'submit.channels.phone' | translate"
                    [(ngModel)]="inv.channels.phone"
                  ></claas-checkbox>
                </li>
                <li>
                  <claas-checkbox
                    [label]="'submit.channels.push' | translate"
                    [(ngModel)]="inv.channels.push"
                  ></claas-checkbox>
                </li>
                <li>
                  <claas-checkbox
                    [label]="'submit.channels.post' | translate"
                    [(ngModel)]="inv.channels.post"
                  ></claas-checkbox>
                </li>
              </div>
              <!-- If type is != new, show text based on invitation type-->
              <div class="marketing-permission-submit-view__footer-suffix-text">
                <span
                *ngIf="(inv.type | lowercase) == 'claas'">{{ 'submit.consent.subtext.claas_only' | translate}}</span>
                <span
                  *ngIf="(inv.type | lowercase) == 'dealer'">{{ 'submit.consent.subtext.claas_and_dealer' | translate}}</span>
                <br>
                <span [innerHTML]="'submit.consent.subtext_2' | translate: {country: this.userCountry}"></span>
                <br>
                <div class="marketing-permission-submit-view__footer-marketing-footnote">
                  <!-- Differentiate between CLAAS & Dealer-->
                  <span *ngIf="(inv.type | lowercase) == 'claas'"><sup>1</sup> {{ this.footnote }}</span>
                  <br>
                  <span *ngIf="(inv.type | lowercase) == 'dealer'"><sup>1</sup>
                    <app-dealer-address-footnote [dealerId]="inv.sap_reference_id"></app-dealer-address-footnote></span>
                </div>
              </div>
            <div class="marketing-permission-submit-view__actions">
              <claas-button
                [text]="'overview.marketing.confirm_accept' | translate"
                [buttonClass]="primaryButtonClass"
                [size]="mediumButtonSize"
                [disabled]="this.disableCompleteReg"
                (click)="next(this.marketingPermissions, stepper)"></claas-button>
            </div>
            <div *ngIf="!this.disableCompleteReg" style="text-align: center;">
              <a href="#" (click)="skipMpContinueRegistration($event, this.marketingPermissions)" type="register">{{ 'overview.marketing.registration' |  translate }}</a>
            </div>
              </div>
          </ul>
        </div>
      </div>
      <div *ngIf="!this.showInvCheckbox && !this.showSpinner">
        {{ 'overview.marketing.no_invitations' |  translate }}
      </div>
    </mat-step>
    <mat-step *ngIf="this.doi" label="{{ 'overview.marketing.complete_registration2' |  translate }}">
      <div class="marketing-permission-submit-view__body-text" [innerHtml]="'submit.complete_registration.text' | translate"></div>
        <div class="marketing-permission-submit-view__body-choices">
          <claas-checkbox
            name="consent"
            [(ngModel)]="this.consentGiven"
          ></claas-checkbox>
          <span [innerHtml]="'submit.complete_registration.consent_text' | translate : { country: this.userCountry }"></span>
        </div>
      <div *ngIf="this.showRegSpinner" class="marketing-permission-submit-view__actions" >
        <claas-circular-progress
          class="spinner"
          [color]="color"
          [diameter]="size"
        ></claas-circular-progress>
      </div>
      <div class="marketing-permission-submit-view__actions" *ngIf="!this.showRegSpinner">
        <claas-button
        [text]="'submit.complete_registration.button' | translate"
        [buttonClass]="primaryButtonClass"
        [size]="mediumButtonSize"
        [disabled]="!this.consentGiven"
        (click)="completeRegistration($event);"></claas-button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
