import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ButtonClass } from '@claas/claas-form-components';
import { Organization } from '../../models/organization.model';
import { UserAddress } from '../../models/user-address.model';
import { OrganisationDealerModel } from '../../models/organisation-dealer.model';
import { Consent } from '../../models/consent.model';
import { UserInformationModel } from '../../models/user-information.model';
import { OrganizationInvitation } from '../../models/organisation-invitation.model';
import { MarketingPermission } from '../../models/marketing-permission.model';
import { UserUpdateRequest } from '../../models/user-update-request.model';
import { PhoneNumber } from '../../models/phone-number.model';
import { OrganisationUpdateRequest } from '../../models/organisation-update-request.model';
import { OrgAddressPatch } from '../../models/org-address-patch.model';
import { UserDeletionRequest } from '../../models/user-deletion-request.model';

export interface PhonenumberDetails {
  currentIndex?: number;
  phoneNumbers: PhoneNumber[];
}

export interface AddressDetails {
  currentIndex?: number;
  addresses: UserAddress[];
}

export enum TargetOptions {
  ORGANISATION = 'organisation',
  PROFILE = 'profile',
  SETTINGS = 'settings'
}

export enum ElementTypeOptions {
  INVOICE = 'INVOICE',
  DELIVERY = 'DELIVERY'
}

export interface passedDataToDialog {
  context?: string;
  subContext?: string;
  target?: TargetOptions;

  avatarUrl?: any;
  nameDetails?: UserInformationModel;
  profileEmail?: string;
  phonenumberDetails?: PhonenumberDetails;
  addressDetails?: AddressDetails;
  language?: string;
  adminOrgs?: Organization[];
  isSocial?: boolean;
  userLanguage?: string;
  userCountry?: string;
  confirmationHeader?: string;
  confirmationText?: string;
  organisationData?: any;
  elementType?: ElementTypeOptions;
  profileRole?: string;
  initialEmail?: string;
  roleOptions?: string[];
  connectedDealers?: OrganisationDealerModel[] | null;
  largeDisplay?: boolean;
  consents?: Consent[];
  applicationId?: string;
  organisationInvitation?: OrganizationInvitation;
  userUpdateRequest?: UserUpdateRequest;
  orgUpdateRequest?: OrganisationUpdateRequest;
  marketingPermission?: MarketingPermission;
  marketingPermissions?: MarketingPermission[];
  userId?: string;
  withUniqueAdmin?: boolean;
  countriesForPhone?: string[];
  id?: string;
  inputAddress?: UserAddress | OrgAddressPatch;
  proposedAddresses?: UserAddress[] | OrgAddressPatch[];
  marketingInvitation?: MarketingPermission[];
  isAdmin?: boolean;
  specificToken?: string;
  dealerSapRefId?: string;
  userDeletionRequest?: UserDeletionRequest;

  buttonDelete?: string;
  buttonCancel?: string;
  buttonSave?: string;
  buttonConfirm?: string;
}

export interface ResponseDataFromDialog {
  context?: string;
  action: string;
  refreshIsRequired?: boolean;
  targetEmail?: string;
  role?: string;
  disconnectedDealers?: OrganisationDealerModel[] | null;
  selectedAddress?: UserAddress | OrgAddressPatch;
  updateStatus?: string;
}

@Component({
  selector: 'app-dialog-window',
  templateUrl: './dialog-window.component.html',
  styleUrls: ['./dialog-window.component.scss']
})
export class DialogWindowComponent {
  errorButtonClass = ButtonClass.ERROR;
  primaryTextButtonClass = ButtonClass.PRIMARY_TEXT;
  secondaryTextButtonClass = ButtonClass.SECONDARY_TEXT;

  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: passedDataToDialog
  ) {}

  closeDialogWindow(passedObject: any): void {
    this.dialogRef.close(passedObject);
  }

  displayPopUp(input: any): void {
    this.snackBar.open(input.text, '', {
      duration: 5000,
      panelClass: input?.className ? input.className : ''
    });
  }
}
