<div class="delete-account__wrapper">
  <p class="delete-account__title">
    {{ 'account_settings.delete_account' | translate }}
  </p>

  <div *ngIf="!showConfirmation" class="delete-account__wrapper">
    <p
      [innerHTML]="'account_settings.delete_text' | translate"
      class="delete-account__info-text"></p>

    <p *ngIf="connectedApps.length === 0" class="delete-account__no-apps">
      {{ 'account_settings.no_connected_apps' | translate }}
    </p>
    <div *ngFor="let app of this.connectedApps">
      <p class="delete-account__app-name">
        {{ getConsentTranslation(app.id, 'name') | translate }}
      </p>
    </div>

    <div *ngIf="!isSocial" class="delete-account__input-wrapper">
      <form [formGroup]="myForm!">
        <mat-form-field
          appearance="outline"
          class="delete-account__input"
          class="w-full"
          subscriptSizing="dynamic">
          <input
            [errorStateMatcher]="
              errorsStatusObject$['password'] | async | translate
            "
            formControlName="password"
            matInput
            placeholder="{{
              'account_settings.password.current_password' | translate
            }}"
            type="{{ showPassword ? 'text' : 'password' }}" />
          <mat-icon (click)="showPassword = !showPassword" matSuffix>
            <claas-icon-button
              [iconSize]="smallIconSize"
              [icon]="showPasswordIcon" />
          </mat-icon>
        </mat-form-field>
        <p
          *ngIf="returnedError === 'password'"
          class="delete-account__password-wrong">
          {{ 'account_settings.password.password_wrong' | translate }}
        </p>
        <p
          *ngIf="returnedError === 'blocked'"
          class="delete-account__password-wrong">
          {{ 'account_settings.password.account_blocked' | translate }}
        </p>
        <p
          *ngIf="returnedError === 'unknown'"
          class="delete-account__password-wrong">
          {{ 'account_settings.password.unknown_error' | translate }}
        </p>
      </form>
    </div>

    <div class="dialog-actions">
      <div class="left-side">
        <div class="btn-left">
          <div *ngIf="showSpinner$ | async" class="flex flex-end w-full">
            <span class="flex">
              <claas-circular-progress [color]="secondaryColor" [diameter]="40">
              </claas-circular-progress>
            </span>
          </div>
        </div>
      </div>
      <div class="right-side">
        <claas-button
          (click)="onCancelClicked()"
          *ngIf="data?.buttonCancel"
          [buttonClass]="secondaryTextButtonClass"
          [text]="'generic.cancel' | translate"
          class="btn-left" />

        <claas-button
          (onClick)="onDeleteClicked()"
          *ngIf="data?.buttonSave"
          [buttonClass]="errorTextButtonClass"
          [disabled]="
            (!!(invalidSaveButtonState$ | async) && !isSocial) ||
            (showSpinner$ | async)
          "
          [text]="'account_settings.delete_account' | translate"
          class="btn-right" />
      </div>
    </div>
  </div>

  <div *ngIf="showConfirmation" class="delete-account__wrapper">
    <p class="delete-account__info-text">
      {{ 'account_settings.delete_org_info' | translate }}
    </p>

    <div *ngFor="let org of adminOrgs">
      <p class="delete-account__app-name">{{ org['name'] }}</p>
    </div>

    <div class="dialog-actions">
      <div class="left-side">
        <div class="btn-left"></div>
      </div>
      <div class="right-side">
        <claas-button
          (onClick)="onSubmitClicked()"
          *ngIf="data?.buttonSave"
          [text]="'generic.confirm' | translate"
          class="btn-right">
        </claas-button>
      </div>
    </div>
  </div>
</div>
