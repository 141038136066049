import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import { ButtonSize } from '@claas/claas-form-components';
import { BehaviorSubject } from 'rxjs';
import { UserAddress } from '../../models/user-address.model';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';

import { errorStatusObject } from '../address-view/address-view.component';
import { OrgAddressPatch } from '../../models/org-address-patch.model';
import { AddressHelperService } from '../../helpers/address.helper.ts.service';

@Component({
  selector: 'app-address-validation-view',
  templateUrl: './address-validation-view.component.html',
  styleUrls: ['./address-validation-view.component.scss']
})
export class AddressValidationViewComponent implements OnInit {
  @Input() data: passedDataToDialog | undefined;

  @Output() closeDialogWindow = new EventEmitter();
  selectedAddress: UserAddress | OrgAddressPatch | undefined;
  largeSize = CircularProgressDiameter.LARGE;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;

  errorsStatusObject$: errorStatusObject = {};
  invalidSaveButtonState$ = new BehaviorSubject(true);
  proposedAddresses$: BehaviorSubject<any> = new BehaviorSubject([]);
  inputAddress$: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(private addressHelper: AddressHelperService) {}

  ngOnInit() {
    if (this.data?.inputAddress) {
      const inputAddressTranslated = this.translateAddress(
        this.data?.inputAddress
      );

      this.inputAddress$.next(inputAddressTranslated);
    }

    if (this.data?.proposedAddresses) {
      const proposedAddressesTranslated = this.translateAddresses(
        this.data?.proposedAddresses
      );

      this.proposedAddresses$.next(proposedAddressesTranslated);
    }
  }

  translateAddresses(
    addresses: UserAddress[] | OrgAddressPatch[]
  ): UserAddress[] | OrgAddressPatch[] {
    const translatedAddresses: any[] = [];

    addresses.forEach((address: UserAddress | OrgAddressPatch) => {
      translatedAddresses.push(this.translateAddress(address));
    });

    return translatedAddresses;
  }

  translateAddress(
    address: UserAddress | OrgAddressPatch
  ): UserAddress | OrgAddressPatch {
    const translatedCountryName = this.addressHelper.translateCountryValue(
      address.country
    );
    const translatedStateName = address.state
      ? this.addressHelper.translateStateValue(address.state, address.country)
      : '';

    return this.data && this.data.target === 'organisation'
      ? new OrgAddressPatch({
          ...address,
          country: translatedCountryName,
          state: translatedStateName
        })
      : new UserAddress({
          ...address,
          country: translatedCountryName,
          state: translatedStateName
        });
  }

  changeSelectedAddress(addressIndex: number) {
    if (addressIndex >= 0 && this.data?.proposedAddresses) {
      this.selectedAddress = this.data?.proposedAddresses[addressIndex];
    } else {
      this.selectedAddress = this.data?.inputAddress;
    }
  }

  onSaveClicked(): void {
    const passedObject = {
      selectedAddress: this.selectedAddress
    };
    this.closeDialogWindow.emit(passedObject);
  }
}
