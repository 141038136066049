import {
  Component,
  EventEmitter,
  Input,
  OnDestroy, OnInit,
  Output
} from '@angular/core';
import {
  passedDataToDialog,
  ResponseDataFromDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { errorStatusObject } from '../address-view/address-view.component';
import { ApiService } from '../../services/api.service';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { catchError, map } from 'rxjs/operators';
import { DealerResponse } from '../../../preference-center/preference-center.component';

@Component({
  selector: 'app-user-deletion-request-view',
  templateUrl: './user-deletion-request-view.component.html',
  styleUrls: ['./user-deletion-request-view.component.scss']
})
export class UserDeletionRequestViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  errorButtonClass = ButtonClass.ERROR;
  myForm: FormGroup | undefined;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;

  subscriptions = new Subscription();
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  dealerName: string = '';

  constructor(
    private apiService: ApiService
  ) {}

  ngOnInit() {
    const dealerSapRefId = this.data?.userDeletionRequest?.dealerSapRefId;
    if (dealerSapRefId) {
      this.getDealerName(dealerSapRefId).pipe(
        tap((name: string) => {
          this.dealerName = name;
        })
      ).subscribe();
    }
  }


  onClose(accepted: boolean): void {
    this.showSpinner$.next(true);
    this.updateUserDeletionRequest(accepted);
  }

  updateUserDeletionRequest(accepted: boolean): void {
    if (this.data.userDeletionRequest) {
      this.data.userDeletionRequest.status = accepted ? 'accepted' : 'denied';

      this.apiService
        .setUserDeletionRequestStatus(this.data.userDeletionRequest)
        .subscribe({
          next: () => {
            const response: ResponseDataFromDialog = {
              action: 'user-deletion',
              updateStatus: this.data.userDeletionRequest?.status,
              refreshIsRequired: this.data.userDeletionRequest?.status !== 'denied'
            };

            this.showSpinner$.next(false);
            this.closeDialogWindow.emit(response);
          },
          error: () => {}
        });
    }
  }

  private getDealerName(dealerId: string): Observable<string> {
    return this.apiService
      .getPreferredDealer(dealerId)
      .pipe(map((res: DealerResponse) => res?.dealerName || ''));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
