import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import {
  addressDetailsLoaded,
  addressDetailsNotLoaded,
  currentOrganisationLoaded,
  loadAddressDetails,
  loadOrganisationRegistrationSteps,
  loadRegistrationDefaultOrganisation,
  loadUserDefaultOrganisation,
  loadUserOrganisationsSummary,
  organisationDataNotLoaded,
  organisationRegistrationStepsLoaded,
  organisationRegistrationStepsNotLoaded,
  registeredOrganisationLoaded,
  registeredOrganisationNotLoaded,
  registerOrganisation,
  registrationDefaultOrganisationLoaded,
  registrationDefaultOrganisationNotLoaded,
  searchAddress,
  searchAddressComplete,
  searchAddressFailure,
  searchAddressSuccess,
  setCurrentOrganisation,
  setUserDefaultOrganisation,
  userDefaultOrganisationLoaded,
  userOrganisationsSummaryLoaded
} from './organisations.actions';
import { mergeMap, of, switchMap, take } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import {
  IAddressAutocompleteRequest,
  IRegisterOrganisationRequestWithMoreParams,
  IRegisterOrganisationResponse,
  IRegistrationCurrentDefaultOrganisation,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { Organization } from '../../models/organization.model';
import { RegistrationDialogError } from '../global/global.state';
import { AuthService } from '../../services/auth.service';
import { State } from '..';
import { Store } from '@ngrx/store';

@Injectable()
export class OrganisationsEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private apiService: ApiService,
    private store: Store<State>
  ) {}

  loadUserOrganisationsSummaryData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserOrganisationsSummary),
      mergeMap(() =>
        this.apiService.getOrganizationsSummary().pipe(
          map(result => {
            const realOrganisations =
              result.content && result.content.length
                ? result.content.slice(1)
                : [];

            return userOrganisationsSummaryLoaded({
              userOrganisationsSummary: realOrganisations
            });
          })
        )
      )
    );
  });

  loadUserDefaultOrganisationData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserDefaultOrganisation),
      mergeMap(action =>
        this.apiService.getDefaultOrganisation(action.userId).pipe(
          map((result: any) =>
            userDefaultOrganisationLoaded({
              defaultOrganisationId: result.orgId
            })
          )
        )
      )
    );
  });

  setUserDefaultOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setUserDefaultOrganisation),
      mergeMap(action =>
        this.apiService
          .setDefaultOrganisation({ orgId: action.orgId }, action.userId)
          .pipe(
            map((result: any) =>
              userDefaultOrganisationLoaded({
                defaultOrganisationId: result.orgId
              })
            )
          )
      )
    );
  });

  loadOrganisationRegistrationStepsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOrganisationRegistrationSteps),
      filter(steps => !!steps),
      take(1),
      switchMap(() =>
        this.apiService.getRegistrationSteps().pipe(
          map(result => result.steps),
          map((result: IRegistrationDialogStep[]) =>
            organisationRegistrationStepsLoaded({
              registrationSteps: result
            })
          ),
          catchError(error =>
            of(
              organisationRegistrationStepsNotLoaded({
                organisationError: error.payload
              })
            )
          )
        )
      )
    );
  });

  loadRegistrationDefaultOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRegistrationDefaultOrganisation),
      switchMap(action => {
        return this.apiService
          .getRegistrationDefaultOrganisation(action.country)
          .pipe(
            map((result: IRegistrationCurrentDefaultOrganisation) =>
              registrationDefaultOrganisationLoaded({
                registrationDefaultOrganisation: result
              })
            ),
            catchError(error =>
              of(
                registrationDefaultOrganisationNotLoaded({
                  organisationError: error.payload
                })
              )
            )
          );
      })
    );
  });

  registerOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registerOrganisation),
      switchMap((request: IRegisterOrganisationRequestWithMoreParams) => {
        return this.apiService.registerOrganisation(request).pipe(
          map((response: IRegisterOrganisationResponse) => {
            return registeredOrganisationLoaded({
              registeredOrganisation: {
                response,
                newOrganisation: true,
                country: request.country
              }
            });
          }),
          catchError(error =>
            of(
              registeredOrganisationNotLoaded({
                registrationError: RegistrationDialogError.STEP_1,
                organisationError: error.payload
              })
            )
          )
        );
      })
    );
  });

  setCurrentOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setCurrentOrganisation),
      map((action: Partial<Organization>) =>
        currentOrganisationLoaded({
          currentOrganisation: {
            auth0Id: action.auth0Id
          }
        })
      )
    );
  });

  loadAddressDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAddressDetails),
      switchMap((action: any) => {
        return this.apiService
          .getRegistrationAddressDetails(action.addressDetailsRequest)
          .pipe(
            map(result => addressDetailsLoaded({ addressDetails: result })),
            catchError(error =>
              of(addressDetailsNotLoaded({ organisationError: error.payload }))
            )
          );
      })
    );
  });

  searchAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAddress),
      map(({ request }) => request),
      mergeMap((request: IAddressAutocompleteRequest) =>
        this.apiService.searchAddress(request).pipe(
          map(response =>
            searchAddressSuccess({ addressSearchResponse: response })
          ),
          catchError(error => of(searchAddressFailure({ error }))),
          finalize(() => this.store.dispatch(searchAddressComplete()))
        )
      )
    )
  );
}
