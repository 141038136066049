import { Component, OnDestroy } from '@angular/core';
import {
  ButtonClass,
  ButtonSize,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import { ApiService } from '../shared/services/api.service';
import { BehaviorSubject, Observable, Subscription, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserDetails } from '../shared/state/user';
import { filter, map } from 'rxjs/operators';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { Router } from '@angular/router';
import {
  addressDetailsLoaded,
  currentOrganisationLoaded,
  loadAddressDetails,
  loadOrganisationRegistrationSteps,
  loadRegistrationDefaultOrganisation,
  loadUserDefaultOrganisation,
  loadUserOrganisationsSummary,
  registerOrganisation
} from '../shared/state/organisations';
import {
  selectAddressDetails,
  selectCurrentOrganisation,
  selectDefaultOrganisation,
  selectOrganisationError,
  selectOrganisationsSummary,
  selectRegisteredOrganisation,
  selectRegistrationDefaultOrganisation,
  selectRegistrationSteps,
  getRegisterOrganisationPending
} from '../shared/state/organisations/organisations.selectors';
import { UserOrganisationSummary } from '../shared/state/organisations/organisations.state';
import { TranslateService } from '@ngx-translate/core';
import {
  IOrganisationRegistrationDialogInput,
  OrganisationRegistrationDialogComponent
} from 'cde-fe-organization-registration-dialog';
import { environment } from '../../environments/environment';
import {
  selectAddressAutocompleteSuggestionsData,
  selectRegistrationDataPending,
  selectRegistrationDealerData,
  selectRegistrationErrorData,
  selectRegistrationPersonalData,
  selectRegistrationPersonalDataPending,
  selectRegistrationShopData
} from '../shared/state/global/global.selectors';
import {
  selectDealerByNameSearchData,
  selectDealerFiltersData,
  selectNearestDealerSearchData,
  selectNearestDealerSearchDataPending
} from '../shared/state/dealers/dealers.selectors';
import { User } from 'cde-fe-organization-registration-dialog/lib/models/auth.model';
import { AuthService } from '../shared/services/auth.service';
import {
  IAddressAutocompleteRequest,
  IAddressDetailsRequest,
  IGetDealerRequest,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  loadDealerByNameSearchData,
  loadDealerFiltersData,
  loadNearestDealersSearchData,
  nearestDealersSearchDataLoaded
} from '../shared/state/dealers';
import {
  clearRegistrationData,
  loadAddressAutocompleteSuggestionsData,
  loadRegistrationDealerData,
  loadRegistrationPersonalData,
  loadRegistrationShopData,
  updateRegistrationPersonalData
} from '../shared/state/global';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AddressHelperService } from '../shared/helpers/address.helper.ts.service';
import {
  getClaasMarketingPermission,
  getDealerMarketingPermissions
} from '../shared/state/marketing-permission/marketing-permission.selectors';
import {
  createClaasMarketingPermission,
  createDealerMarketingPermission,
  getClaasMarketingPermission as getClaasMarketingPermissionFromBackend,
  getDealerMarketingPermission as getDealerMarketingPermissionFromBackend
} from '../shared/state/marketing-permission/marketing-permission.actions';
import { getFeatureToggles } from '../shared/state/feature-toggle/feature-toggle.selectors';
import { Features } from '../shared/models/feature-toggle.model';
import { Member } from '../shared/models/member.model';
import { OrganisationMember } from '../shared/models/organisation-user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserOrgaAddress } from '../shared/models/user-orga-address.model';

declare const window: any;

@Component({
  selector: 'app-organisation',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent implements OnDestroy {
  largeSize = CircularProgressDiameter.LARGE;
  primaryButtonClass = ButtonClass.PRIMARY;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  isHandset = false;
  addIcon: Icon = {
    iconId: '000973',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };
  currentUser: User | undefined = undefined;
  accessToken = '';
  registrationSteps: IRegistrationDialogStep[] | undefined = [];
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;

  organisationsSummary$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  isLoadingOrganisations$: BehaviorSubject<any> = new BehaviorSubject(true);
  private subscriptions = new Subscription();
  private dialogSubscriptions: Subscription | null = null;
  private marketingPermissionFeatureToggle = false;

  constructor(
    private store: Store,
    private apiService: ApiService,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private addressHelper: AddressHelperService,
    private snackBar: MatSnackBar
  ) {
    this.store.dispatch(loadOrganisationRegistrationSteps());
    this.resetPropsFromState();
    this.getOrganisations();

    this.subscriptions.add(
      this.authService
        .getTokenSilently$()
        .subscribe(token => (this.accessToken = token))
    );

    this.subscriptions.add(
      this.store
        .select(selectRegistrationSteps)
        .subscribe(steps => (this.registrationSteps = steps))
    );

    this.subscriptions.add(
      this.store
        .select(getFeatureToggles)
        .subscribe(
          featureToggles =>
            (this.marketingPermissionFeatureToggle =
              featureToggles?.some(
                toggle => toggle.id === Features.MARKETING_PERMISSION
              ) ?? false)
        )
    );
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  resetPropsFromState(): void {
    this.store.dispatch(addressDetailsLoaded({ addressDetails: null }));
    this.store.dispatch(
      nearestDealersSearchDataLoaded({
        nearestDealersSearchData: null
      })
    );
    this.store.dispatch(
      currentOrganisationLoaded({ currentOrganisation: null })
    );
  }

  getRole(role: string) {
    let r = '';
    const split = role.split('.');
    const passedKey = 'organization.roles.' + split.pop();

    this.translateService
      .get(passedKey)
      .subscribe((text: string) => (r = text));
    return r;
  }

  getOrganisations() {
    this.subscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(
          filter((user: any) => !!user),
          take(1),
          tap((user: any) => {
            this.currentUser = user;

            this.store.dispatch(loadUserOrganisationsSummary());

            this.store.dispatch(
              loadUserDefaultOrganisation({ userId: user.uuid })
            );

            this.subscriptions.add(
              this.store
                .select(selectOrganisationsSummary)
                .pipe(
                  filter((organisationsSummary: any) => !!organisationsSummary),
                  take(1),
                  map((organisationsSummary: UserOrganisationSummary[]) => {
                    if (organisationsSummary.length === 0) {
                      // the user has no registered organisation
                      this.isLoadingOrganisations$.next(false);
                    } else {
                      organisationsSummary =
                        this.translatePropertiesSummaryValues(
                          organisationsSummary
                        );

                      organisationsSummary = this.addSummaryMyRoleProperty(
                        organisationsSummary,
                        user.sub
                      );

                      this.setIsDefaultProperty(organisationsSummary);
                    }
                  })
                )
                .subscribe()
            );
          })
        )
        .subscribe()
    );
  }

  translatePropertiesSummaryValues(
    organisations: UserOrganisationSummary[]
  ): UserOrganisationSummary[] {
    const organisationsWithTranslations: UserOrganisationSummary[] = [];

    organisations.forEach((org: UserOrganisationSummary, index) => {
      if (!org['addresses'] || !org['addresses'].length) {
        organisationsWithTranslations[index] = { ...org };
      } else {
        const orgAddress: UserOrgaAddress[] = org['addresses'].slice(0, 1);

        const translatedCountryName = this.addressHelper.translateCountryValue(
          orgAddress[0].country.toLowerCase()
        );
        const translatedStateName = orgAddress[0].state
          ? this.addressHelper.translateStateValue(
              orgAddress[0].state,
              orgAddress[0].country.toLowerCase()
            )
          : '';

        orgAddress[0] = new UserOrgaAddress({
          ...orgAddress[0],
          state: translatedStateName,
          country: translatedCountryName
        });

        organisationsWithTranslations[index] = {
          ...org,
          addresses: orgAddress
        };
      }
    });

    return organisationsWithTranslations;
  }

  setIsDefaultProperty(organisationsSummary: UserOrganisationSummary[]): void {
    this.subscriptions.add(
      this.store.select(selectDefaultOrganisation).subscribe((orgId: any) => {
        if (orgId) {
          const targetIndex = organisationsSummary.findIndex(
            element => element['auth0Id'] === orgId
          );
          const currentDefaultOrganisation = organisationsSummary.splice(
            targetIndex,
            1
          )[0];

          currentDefaultOrganisation['isDefault'] = true;
          organisationsSummary.splice(0, 0, currentDefaultOrganisation);
        }

        this.organisationsSummary$.next(organisationsSummary);
        this.isLoadingOrganisations$.next(false);
      })
    );
  }

  addSummaryMyRoleProperty(
    organisationsSummary: UserOrganisationSummary[],
    myUserId: string
  ): UserOrganisationSummary[] {
    const organisationsWithMyRole: UserOrganisationSummary[] = [];

    organisationsSummary.forEach((org, index) => {
      let myRole;
      const member = org['members'].find(
        (member: Member) =>
          member.userId === myUserId ||
          'auth0|'.concat(member.userId) === myUserId
      );

      if (member) {
        myRole = 'organization.roles.' + member.role.toLowerCase();
      } else {
        myRole = null;
      }

      organisationsWithMyRole.push({ ...org, myRole });
    });

    return organisationsWithMyRole;
  }

  openDetailsPage(orgaDetails: any): void {
    this.router.navigate(['/organisation-data'], {
      queryParams: { orgId: orgaDetails.auth0Id }
    });
  }

  openRegistrationDialog(): void {
    const data = this.createDialogInput();

    const dialogRef = this.dialog.open(
      OrganisationRegistrationDialogComponent,
      {
        data: data,
        disableClose: true
      }
    );

    this.processWithDialogRef(dialogRef);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((response: any) => {
        this.dialogSubscriptions?.unsubscribe();

        if (response && response !== '') {
          this.isLoadingOrganisations$.next(true);

          this.store
            .select(selectRegisteredOrganisation)
            .pipe(filter((result: any) => !!result))
            .subscribe(result => this.getOrganisations());
        }
      });
  }

  openDialog(
    context: string,
    specificToken?: string,
    organisationData?: any
  ): void {
    const data: passedDataToDialog = {
      context,
      specificToken,
      organisationData,
      buttonCancel: 'Cancel',
      buttonSave: 'Save'
    };

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          tap((answer: ResponseDataFromDialog | undefined) => {
            if (answer?.refreshIsRequired) {
              this.getOrganisations();
            }
          })
        )
        .subscribe()
    );
  }

  processWithDialogRef(
    dialogRef: MatDialogRef<OrganisationRegistrationDialogComponent>
  ): void {
    this.dialogSubscriptions = new Subscription();
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getDealerFilter.subscribe(() =>
        this.store.dispatch(loadDealerFiltersData())
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getNearestDealers.subscribe(
        (request: any) => {
          this.store.dispatch(loadNearestDealersSearchData(request));
        }
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getAddressAutocomplete.subscribe(
        (request: IAddressAutocompleteRequest) =>
          this.store.dispatch(
            loadAddressAutocompleteSuggestionsData({
              addressDetailsRequest: request
            })
          )
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getDealersByName.subscribe((request: any) => {
        this.store.dispatch(loadDealerByNameSearchData(request));
      })
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.clearRegistrationData.subscribe(() =>
        this.store.dispatch(clearRegistrationData())
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getRegistrationDefaultOrganisation.subscribe(
        (country: string) =>
          this.store.dispatch(loadRegistrationDefaultOrganisation({ country }))
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getRegistrationShopData.subscribe(
        (request: any) =>
          this.store.dispatch(loadRegistrationShopData({ country: request }))
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getRegistrationPersonalData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationPersonalData({
              country: request.country,
              language: request.language
            })
          );
        }
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getRegistrationDealerData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationDealerData({
              language: request.language
            })
          );
        }
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.maintainPersonalData.subscribe(
        (request: any) =>
          this.store.dispatch(
            updateRegistrationPersonalData({
              request: request.request,
              language: request.language
            })
          )
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.registerOrganisationWithDealerAndShops.subscribe(
        (request: any) => this.store.dispatch(registerOrganisation(request))
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.stepperError.subscribe((message: string) => {
        const translatedMsg = this.translateService.instant(message);
        this.displayPopup(translatedMsg, 'error');
      })
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getAddressDetails.subscribe(
        (request: IAddressDetailsRequest) =>
          this.store.dispatch(
            loadAddressDetails({ addressDetailsRequest: request })
          )
      )
    );

    // Marketing permissions
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getClaasMarketingPermission.subscribe(() =>
        this.store.dispatch(getClaasMarketingPermissionFromBackend())
      )
    );
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getDealerMarketingPermission.subscribe(
        (request: IGetDealerRequest) =>
          this.store.dispatch(
            getDealerMarketingPermissionFromBackend({
              dealerId: request.dealerId,
              language: request.language
            })
          )
      )
    );
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.createClaasMarketingPermission.subscribe(
        request =>
          this.store.dispatch(createClaasMarketingPermission({ request }))
      )
    );
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.createDealerMarketingPermission.subscribe(
        request =>
          this.store.dispatch(createDealerMarketingPermission({ request }))
      )
    );
  }

  // do not use extra class anymore to avoid inconsistencies between interface
  // and class when the version of the dialog changes
  private createDialogInput(
    input?: Partial<IOrganisationRegistrationDialogInput>
  ): Partial<IOrganisationRegistrationDialogInput> {
    return {
      user: this.currentUser,
      dialogSteps: this.registrationSteps ? this.registrationSteps : [],
      disableAutostart: true,
      registrationDataPending$: this.store.select(
        selectRegistrationDataPending
      ),
      searchNearestDealerPending$: this.store.select(
        selectNearestDealerSearchDataPending
      ),
      currentDefaultOrganisation$: this.store.select(
        selectRegistrationDefaultOrganisation
      ),
      currentOrganisation$: this.store.select(selectCurrentOrganisation),
      registrationDealerData$: this.store.select(selectRegistrationDealerData),
      searchNearestDealers$: this.store.select(selectNearestDealerSearchData),
      dealerFilters$: this.store.select(selectDealerFiltersData),
      addressAutocompleteSuggestions$: this.store.select(
        selectAddressAutocompleteSuggestionsData
      ),
      addressDetails$: this.store.select(selectAddressDetails),
      searchDealersByName$: this.store.select(selectDealerByNameSearchData),
      registrationPersonalData$: this.store.select(
        selectRegistrationPersonalData
      ),
      registrationError$: this.store.select(selectRegistrationErrorData),
      organisationsError$: this.store.select(selectOrganisationError),
      getRegisteredOrganisation$: this.store.select(
        selectRegisteredOrganisation
      ),
      registrationDefaultOrganisation$: this.store.select(
        selectRegistrationDefaultOrganisation
      ),
      registrationPersonalDataPending$: this.store.select(
        selectRegistrationPersonalDataPending
      ),
      registrationShopData$: this.store.select(selectRegistrationShopData),
      bearerToken: this.accessToken,
      apiBaseUrl: environment.base_urls.cc_3_api_base_url,
      clientId: environment.auth.azure_maps_client_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UC_UI: window.UC_UI,
      claasMarketingPermission$: this.store.select(getClaasMarketingPermission),
      dealerMarketingPermissions$: this.store.select(
        getDealerMarketingPermissions
      ),
      marketingPermissionFeatureToggle: this.marketingPermissionFeatureToggle,
      registerOrganisationPending$: this.store.select(
        getRegisterOrganisationPending
      ),
      ...(input ?? {})
    };
  }

  private displayPopup(text: string, className?: string): void {
    this.snackBar.open(text, '', {
      duration: 5000,
      panelClass: className ? className : ''
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
