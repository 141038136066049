import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import * as UserActions from '../shared/state/user/user.actions';
import { loadUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isException = false;
  constructor(
    private auth0: AuthService,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      const invitationParameter = route.queryParams['invitation'];
      const organizationParameter = route.queryParams['organization'];
      // If organization invitation is given, trigger authentication with parameters
      if (invitationParameter && organizationParameter) {
        this.isException = true;
        const authorizationParams = {
          organization: organizationParameter,
          invitation: invitationParameter,
          redirect_uri: window.location.origin,
          screen_hint: 'signup'
        };

        this.auth0.loginWithRedirect({
          authorizationParams,
          appState: { target: '/' }
        });
      } else {
        if (
          this.router.url.includes('/terms') ||
          this.router.url.includes('/privacy') ||
          this.router.url.includes('/about') ||
          this.router.url.includes('/org-selection') ||
          this.router.url.includes('/verified') ||
          this.router.url.includes('/submit') ||
          (this.router.url.includes('/email') && res.client_id && res.user_id)
        ) {
          this.isException = true;
        }
      }
    });

    return this.auth0.isAuthenticated$.pipe(
      tap(loggedIn => {
        if (!this.isException) {
          if (!loggedIn) {
            this.auth0.loginWithRedirect({ appState: { target: state.url } });
          } else {
            this.store.dispatch(UserActions.allAuthActions.checkLogin());
            this.store.dispatch(loadUserCoordinates());
          }
        }
      })
    );
  }
}
