<div class="flex flex-col">
  <h1 class="subtitle-2 org-update__header">
    {{ 'updates.organization_update' | translate }}
  </h1>

  <p class="org-update__text">
    {{ 'updates.update_request_org' | translate }}
  </p>

  <div class="org-update__grid-container">
    <!--    HEADER-->
    <div class="org-update__grid-header">
      <p class="org-update__grid-cell"></p>
      <p class="org-update__grid-cell">
        {{ 'updates.existing_data' | translate }}
      </p>
      <p class="org-update__grid-cell">{{ 'updates.change' | translate }}</p>
    </div>

    <claas-divider class="org-update__grid-divider" />

    <div *ngFor="let field of orgData" class="org-update__grid-row">
      <p class="org-update__grid-cell">{{ field.label | translate }}</p>
      <!--      OLD VALUES-->
      <p
        *ngIf="
          field.key !== 'invoice_address.country' &&
          field.key !== 'delivery_address.country' &&
          field.key !== 'company_type' &&
          field.key !== 'delivery_address.type' &&
          field.key !== 'invoice_address.type'
        "
        class="org-update__grid-cell-old">
        {{ field.existingValue }}
      </p>
      <p
        *ngIf="field.key === 'delivery_address.type' && this.orgDeliveryAddress"
        class="org-update__grid-cell-old">
        {{ 'registration.formfield.select.' + this.orgDeliveryAddress.type | translate }}
      </p>
      <p
        *ngIf="field.key === 'invoice_address.type' && this.orgInvoiceAddress"
        class="org-update__grid-cell-old">
        {{ 'registration.formfield.select.' + this.orgInvoiceAddress.type | translate }}
      </p>
      <p
        *ngIf="
          field.key === 'delivery_address.country' && this.orgDeliveryAddress
        "
        class="org-update__grid-cell-old">
        {{ getCountry(this.orgDeliveryAddress.country) | translate }}
      </p>
      <p
        *ngIf="
          field.key === 'invoice_address.country' && this.orgInvoiceAddress
        "
        class="org-update__grid-cell-old">
        {{ getCountry(this.orgInvoiceAddress.country) | translate }}
      </p>
      <p *ngIf="field.key === 'company_type'" class="org-update__grid-cell-old">
        {{ 'registration.formfield.select.' + this.organisation.type | translate }}
      </p>

      <!--      NEW VALUES-->
      <p
        *ngIf="
          field.key !== 'invoice_address.country' &&
          field.key !== 'delivery_address.country' &&
          field.key !== 'company_type' &&
          field.key !== 'delivery_address.type' &&
          field.key !== 'invoice_address.type'
        "
        class="org-update__grid-cell-new subtitle-2">
        {{ field.updatedValue }}
      </p>
      <p
        *ngIf="
          field.key === 'delivery_address.type' ||
          field.key === 'invoice_address.type'
        "
        class="org-update__grid-cell-new subtitle-2">
        {{ 'registration.formfield.select.' + field.updatedValue | translate }}
      </p>
      <p
        *ngIf="
          field.key === 'delivery_address.country' ||
          field.key === 'invoice_address.country'
        "
        class="org-update__grid-cell-new subtitle-2">
        {{ getCountry(field.updatedValue) | translate }}
      </p>
      <p
        *ngIf="field.key === 'company_type'"
        class="org-update__grid-cell-new subtitle-2">
        {{ 'registration.formfield.select.' + field.updatedValue | translate }}
      </p>
      <claas-divider class="org-update__grid-divider" />
    </div>
  </div>
  <div class="org-update__dialog-actions">
    <div class="left-side">
      <div class="btn-left">
        <div *ngIf="showSpinner$ | async" class="flex flex-end w-full">
          <span class="flex">
            <claas-circular-progress
              [color]="secondaryColor"
              [diameter]="largeSize">
            </claas-circular-progress>
          </span>
        </div>
      </div>
    </div>
    <div class="right-side">
      <claas-button
        (onClick)="onClose(false)"
        *ngIf="data.buttonCancel"
        [buttonClass]="secondaryTextButtonClass"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.deny' | translate"
        class="btn-left">
      </claas-button>

      <claas-button
        (onClick)="onClose(true)"
        *ngIf="data?.buttonSave"
        [buttonClass]="primaryButtonClass"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.confirm' | translate"
        class="btn-right" />
    </div>
  </div>
</div>
