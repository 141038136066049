import { ApiService } from './services/api.service';
import { effects, reducers } from './state';
import { environment } from '../../environments/environment';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthService } from './services/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DialogWindowComponent } from './components/dialog-window/dialog-window.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AvatarViewComponent } from './components/avatar-view/avatar-view.component';
import { ClaasFormComponentsModule } from '@claas/claas-form-components';
import { ClaasLayoutComponentsModule } from '@claas/claas-layout-components';
import { PasswordViewComponent } from './components/password-view/password-view.component';
import { LanguageViewComponent } from './components/language-view/language-view.component';
import { PhonenumberViewComponent } from './components/phonenumber-view/phonenumber-view.component';
import { InternationalPhoneComponent } from './components/international-phone/international-phone.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DeleteAccountViewComponent } from './components/delete-account-view/delete-account-view.component';
import { TwoFactorViewComponent } from './components/two-factor-view/two-factor-view.component';
import { NameViewComponent } from './components/name-view/name-view.component';
import { AddressViewComponent } from './components/address-view/address-view.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { OrganisationNameViewComponent } from './components/organisation-name-view/organisation-name-view.component';
import { OrganisationTypeViewComponent } from './components/organisation-type-view/organisation-type-view.component';
import { OrganisationTimezoneViewComponent } from './components/organisation-timezone-view/organisation-timezone-view.component';
import { OrganisationUnitsystemViewComponent } from './components/organisation-unitsystem-view/organisation-unitsystem-view.component';
import { OrganisationPhonenumberViewComponent } from './components/organisation-phonenumber-view/organisation-phonenumber-view.component';
import { OrganisationUsersViewComponent } from './components/organisation-users-view/organisation-users-view.component';
import { OrganisationDeletionRequestViewComponent } from './components/organisation-deletion-request-view/organisation-deletion-request-view.component';
import { ChipComponent } from './elements/chip/chip.component';
import { MatButtonModule } from '@angular/material/button';
import { ClaasFilterComponentsModule } from '@claas/claas-filter-components';
import { MatChipsModule } from '@angular/material/chips';
import { ClaasIconElementsComponentModule } from '@claas/claas-icon-elements-component';
import { ButtonComponent } from './elements/button/button.component';
import { OrganisationConnectedDealersViewComponent } from './components/organisation-connected-dealers-view/organisation-connected-dealers-view.component';
import { MatIconModule } from '@angular/material/icon';
import { ApplicationDialogComponent } from './components/application-dialog/application-dialog.component';
import { MarketingPermissionViewComponent } from './components/marketing-permission-view/marketing-permission-view.component';
import { MarketingPermissionDeleteViewComponent } from './components/marketing-permission-delete-view/marketing-permission-delete-view.component';
import { AdminInvitationViewComponent } from './components/invitations/admin-invitation-view/admin-invitation-view.component';
import { UserInvitationViewComponent } from './components/invitations/user-invitation-view/user-invitation-view.component';
import { UserUpdateViewComponent } from './components/user-update-view/user-update-view.component';
import { OrganisationUpdateViewComponent } from './components/organisation-update-view/organisation-update-view.component';
import { AddressValidationViewComponent } from './components/address-validation-view/address-validation-view.component';
import { AddressService } from './services/address.service';
import { AddressPipe } from './pipes/address/address.pipe';
import { EmailViewComponent } from './components/email-view/email-view.component';
import { UnavailableAppViewComponent } from './components/unavailable-app-view/unavailable-app-view.component';
import { CompleteViewComponent } from './components/complete-view/complete-view.component';
import { MatStepperModule } from '@angular/material/stepper';
import { OrgSelectionComponent } from '../org-selection/org-selection.component';
import { MarketingPermissionSubmitViewComponent } from './components/marketing-permission-submit-view/marketing-permission-submit-view.component';
import { DealerAddressComponent } from './components/dealer-address-footnote/dealer-address.component';
import { MarketingPermissionUpdateViewComponent } from './components/marketing-permission-update-view/marketing-permission-update-view.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import {
  UserDeletionRequestViewComponent
} from './components/user-deletion-request-view/user-deletion-request-view.component';
import { InvitationRequestDialogComponent } from '../organisation-data/invitation-request-dialog/invitation-request-dialog.component';

/**
 * CoreModule
 *
 * The `CoreModule` is a singleton providing module.
 *
 * Services and shared reducers should be provided just once when the application loads, so this file
 * is imported directly into `app.module.ts`.
 *
 * This file is intended for *app-wide* singletons. Singletons that are used only by the
 * stand-alone components in `etv-utils` should be provided in their respective
 * modules.
 */

const PRROVIDERS = [
  // SERVICES
  ApiService,
  AuthService,
  TranslateService,
  AddressService
];

const COMPONENTS = [
  DialogWindowComponent,
  AvatarViewComponent,
  PasswordViewComponent,
  LanguageViewComponent,
  PhonenumberViewComponent,
  InternationalPhoneComponent,
  DeleteAccountViewComponent,
  TwoFactorViewComponent,
  NameViewComponent,
  AddressViewComponent,
  ChipComponent,
  ConfirmationDialogComponent,
  OrganisationNameViewComponent,
  OrganisationTypeViewComponent,
  OrganisationTimezoneViewComponent,
  OrganisationUnitsystemViewComponent,
  OrganisationPhonenumberViewComponent,
  OrganisationUsersViewComponent,
  ButtonComponent,
  OrganisationConnectedDealersViewComponent,
  ApplicationDialogComponent,
  MarketingPermissionViewComponent,
  MarketingPermissionDeleteViewComponent,
  AdminInvitationViewComponent,
  UserInvitationViewComponent,
  EmailViewComponent,
  UnavailableAppViewComponent,
  OrgSelectionComponent,
  UnavailableAppViewComponent,
  CompleteViewComponent,
  AddressValidationViewComponent,
  MarketingPermissionSubmitViewComponent,
  DealerAddressComponent,
  OrganisationDeletionRequestViewComponent,
  UserDeletionRequestViewComponent,
  InvitationRequestDialogComponent
];

@NgModule({
  imports: [
    MatDialogModule,
    MatSnackBarModule,
    NgxMatIntlTelInputComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    StoreModule.forRoot(reducers),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * This should include all Effects defined under `shared/state`!
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([...effects]),
    ClaasFormComponentsModule,
    ClaasLayoutComponentsModule,
    FormsModule,
    TranslateModule,
    ClaasFilterComponentsModule,
    MatChipsModule,
    ClaasIconElementsComponentModule,
    MatIconModule,
    UserUpdateViewComponent,
    OrganisationUpdateViewComponent,
    MarketingPermissionUpdateViewComponent,
    MatCheckbox,
    MatSelect
  ],
  declarations: [...COMPONENTS, AddressPipe],
  providers: [...PRROVIDERS],
  exports: [...COMPONENTS, ButtonComponent, AddressPipe]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
